import { LicenseDescriptionProps } from '../../../types/components/license-confirmation-minimal-flow/shared/LicenseDescription';

import { LicenseIcon } from '../../../modules/Icons';

import { licenseDisqualifications } from '../constants';
import { LicenseId } from '../../../types/api/LicenseTypesTypes';

const LicenseDescription: React.FC<LicenseDescriptionProps> = ({
  licenseId,
}) => {
  const paragraphClass = 'text-16 leading-24 text-a-gray inter font-normal mb-[24px]'
  const textEmphasisClass = 'text-white font-bold';

  const getLicenseName = (): string => {
    switch (licenseId) {
      case 1:
        return 'Individual';
      case 2:
        return 'Startup';
      case 3:
      case 4:
      case 5:
      case 6:
        return 'Enterprise';
      default:
        return '';
    };
  };

  const renderIndividualLicenseDescription = (): React.ReactElement => {
    return (
      <p className={paragraphClass} cy-test-id="individual-license-description">
        This license type includes full commercial rights for <span className={textEmphasisClass}>1 (one) person</span> making less than $200K USD per year in revenue or funding. Any files licensed with this type must never be accessible to anyone other than the named licensee.
      </p>
    );
  };

  const renderStartupLicenseDescription = (): React.ReactElement => {
    return (
      <p className={paragraphClass} cy-test-id="startup-license-description">
        This license type includes full commercial rights for <span className={textEmphasisClass}>1 (one) company</span> and covers all its artists and physical locations, as long as the total annual revenue and funding of the company is less than $3M USD.
      </p>
    );
  };

  const renderEnterpriseLicenseDescription = (tier: String): React.ReactElement => {
    return (
      <p className={paragraphClass} cy-test-id="enterprise-license-description">
        This license type includes full commercial rights for a company making at least $3M USD per year in revenue or funding. It covers all its artists, physical locations, and subsidiaries. You selected the annual revenue range of <span className={textEmphasisClass}>{tier}</span> USD. If this is correct, you may continue.
      </p>
    )
  };

  const getVerbalRevenueTier = (id: LicenseId): String => {
    switch (id) {
      case 3:
        return '$3M-$20M';
      case 4:
        return '$21M-$49M'
      case 5:
        return '$50M-$99M'
      case 6:
        return 'Over $100M'

    }
  }

  const renderLicenseDescription = (): React.ReactElement => {
    switch (licenseId) {
      case 1:
        return renderIndividualLicenseDescription();
      case 2:
        return renderStartupLicenseDescription();
      case 3:
      case 4:
      case 5:
      case 6:
        return renderEnterpriseLicenseDescription(getVerbalRevenueTier(licenseId));
      default: return <></>;
    }
  };

  const renderLicenseDisqualifications = (): React.ReactElement => {
    if (!licenseDisqualifications[licenseId]?.length) return;
    return (
      <div className="flex flex-col w-full gap-2" cy-test-id="license-disqualifications">
        <span className="font-bold text-white inter text-16 leading-24">
          You do not qualify for the "{getLicenseName()}" license if:
        </span>
        <ul className="w-full pl-5 list-disc">
          {
            licenseDisqualifications[licenseId].map(discqualification => (
              <li className="font-normal text-gray-500 inter text-16 leading-24">
                {discqualification}
              </li>
            ))
          }
        </ul>
      </div>
    )
  }

  return (
    <div
      cy-test-id="license-description"
      className="flex flex-col w-full gap-[24px]"
    >
      <p className="flex gap-2.5 text-24 font-bold text-white leading-40" cy-test-id="license-name">
        <LicenseIcon className="w-[30px] h-[30px]"/> {getLicenseName()}
      </p>
      {renderLicenseDescription()}
      {renderLicenseDisqualifications()}
    </div>
  )
};

export default LicenseDescription;
