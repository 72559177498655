import { CardVendorLogoProps } from '../../types/components/shared/CardVendorLogo';

import Image from 'next/image';
import { cardBrandNames } from '../../constants/stripe'

const CardVendorLogo: React.FC<CardVendorLogoProps> = (props: CardVendorLogoProps) => {
  const { card, width = 42, height = 30, className = '' } = props;
  const vendorName = card?.display_brand || '';

  if (!cardBrandNames.find(name => name === vendorName)) return (
    <div
      cy-test-id="unknown-vendor-placeholder"
      className={`w-[${width}px] h-[${height}px] rounded-[2px] bg-a-lighter-gray ${className}`}
    >
    </div>
  );

  // !! IMPORTANT !! - card icon file names are derived from Stripe brand names, change with care
  const imageSource = `/img/cards/${vendorName}.png`;

  return (
    <Image
      src={imageSource}
      width={width}
      height={height}
      alt={vendorName}
      className={className}
    />
  )
}

export default CardVendorLogo;
