import { Dialog } from '@headlessui/react';
import { NewExitIcon } from '../../modules/Icons';
import React from 'react';
import { BasicModalProps } from '../../types/components/shared/BasicModalTypes';

const BasicModal: React.FC<BasicModalProps> = (props: BasicModalProps) => {
  const { visible, setVisible, content } = props;

  function modalBgColor(content: string) {
    switch (content) {
      case 'pfl-pricing':
        return 'bg-gray-800';
      case 'login-redirect':
        return 'bg-gray-900';
      case 'download-limit':
        return 'bg-gray-900';
      default:
        return 'bg-[#1A1D1F]';
    }
  }

  function renderExitIcon(content) {
    const hiddenContents = ['pfl-pricing', 'download-limit', 'checkout', 'category'];
    return hiddenContents.includes(content) ? '' : 'hidden';
  }

 return (
    <div className={props.className}>
      <Dialog
        open={visible}
        onClose={() => {
          setVisible(false);
        }}
       className="relative z-50">
        <div className="static inset-0 flex items-center justify-center bg-black/60">
         <Dialog.Panel className="overflow-x-hidden max-h-[896px]">
            <div
             className={`basic-modal ${modalBgColor(content)} bg-a-grey shadow-lg drop-shadow-lg rounded-[5px] text-white max-w-[1490px]
                ${content !== 'category' && '!max-w-[800px] w-full px-4 py-8 md:py-8 md:px-8 !max-h-[100vh] overflow-y-auto overflow-x-hidden'
               } lg:mx-12`}>
              {props.children}
              <NewExitIcon
                className={`${renderExitIcon(content)} absolute top-6 right-6 cursor-pointer !stroke-[#F5F5F5] opacity-[50%] w-[18px] h-[18px] scale-125`}
                onClick={() => setVisible(false)}
              />
            </div>
          </Dialog.Panel>
        </div>
      </Dialog>
    </div>
  );
};

export default BasicModal;
