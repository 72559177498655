import { CheckoutPanelProps } from '../types/modules/Panels';

export const CheckoutPanel: React.FC<CheckoutPanelProps> = (props: CheckoutPanelProps) => {
  const { testId, children } = props;

  return (
    <div cy-test-id={testId || 'checkout-panel'} className="flex py-3 px-3 md:py-[23px] md:px-[25px] rounded-[5px] bg-gray-800 border-[#4D5457] border-[1px] md:min-h-[70px]">
      {children}
    </div>
  );
}
