export const EVENT_NAMES = {
  VIEW_PRODUCT: 'Product Viewed',
  CLICK_PRODUCT: 'Product Clicked',
  VIEW_CATEGORY: 'Category Viewed',
  CLICK_CATEGORY: 'Category Clicked',
  ADD_TO_CART: 'Add to Cart',
  ADD_TO_WISHLIST: 'Add to Wishlist',
  PRODUCT_PURCHASED_CREDITS: 'Product purchased [credits]',
  PRODUCT_PURCHASED_MONEY: 'Product purchased [money]',
};

export const ATTRIBUTES = {
  CATEGORIES: 'categories',
};
