import React from 'react';
import { connect, useDispatch } from 'react-redux';
import { ApplicationState } from '../../types/state/storeTypes';
import { OnboardingFlowModalProps } from '../../types/components/onboarding-flow-modal/OnboardingFlowModal';
import { ONBOARDING_MODAL_ACTIONS } from '../../constants/actions';

const { CLOSE_ONBOARDING_MODAL } = ONBOARDING_MODAL_ACTIONS;

import { Dialog } from '@headlessui/react';
import OnboardingFlow from '../onboarding-flow/OnboardingFlow';

const OnboardingFlowModal: React.FC<OnboardingFlowModalProps> = ({
  isOpen = false,
  setIsOpen,
  isOnboardingModalOpen = false,
  isEnterpriseFlow = false
}) => {
  const dispatch = useDispatch();

  const close = () => {
    if (setIsOpen) {
      setIsOpen(false);
    }

    dispatch({ type: CLOSE_ONBOARDING_MODAL });
  };

  const modalIsOpen = isOpen || isOnboardingModalOpen;

  return (
    <Dialog open={modalIsOpen} onClose={close} className="relative z-[99]">
      <div className={`fixed inset-0 bg-black/60`} />
      <div className="fixed inset-0 overflow-y-auto">
        <div className="flex items-center justify-center min-h-full text-center">
          <Dialog.Panel className="w-full max-w-[860px] transform overflow-hidden rounded-[10px] px-5 lg:px-[48px] pt-[36px] pb-[50px] text-left align-middle shadow-xl transition-all bg-gray-900">
            <OnboardingFlow
              doNotShowPaymentMethodStep={true}
              onLicenseSelected={close} 
              isEnterpriseFlow={isEnterpriseFlow}
            />
          </Dialog.Panel>
        </div>
      </div>
    </Dialog>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  isOnboardingModalOpen: state.onboardingModal?.isOnboardingModalOpen,
  isEnterpriseFlow: state.onboardingModal?.isEnterpriseFlow
});

export default connect(mapStateToProps)(OnboardingFlowModal);
