import { ConfirmLicenseStepProps, ConfirmLicenseStepStateProps } from '../../../types/components/onboarding-flow/steps/ConfirmLicenseStep';
import { LicenseId } from '../../../types/api/LicenseTypesTypes';
import { ApplicationState } from '../../../types/state/storeTypes';
import { User } from '../../../types/api/UsersTypes';

import LicenseConfirmationMinimalFlow from '../../license-confirmation-minimal-flow/LicenseConfirmationMinimalFlow';
import { LicenseIcon } from '../../../modules/Icons';

import { CONFIRM_LICENSE_STEP } from '../constants';

import { useState, useEffect } from 'react';
import { connect } from 'react-redux';

const ConfirmLicenseStep: React.FC<ConfirmLicenseStepProps> = ({
  currentStep,
  onStepCompleted,
  user,
  displayUnknownLicenseUi = false,
  doNotShowStepOrderNumber = false,
  customStepTitle,
}) => {
  const [stepCompleted, setStepCompleted] = useState<boolean>(false);
  const [licenseId, setLicenseId] = useState<LicenseId>();

  useEffect(() => {
    const profileLicenseId = user?.team?.licensee_profile?.license_type?.id;
    const minLicenseId = user?.min_license_id;

    // Case 1: Only profile license ID exists
    if (profileLicenseId && !minLicenseId) {
      onFlowCompleted(profileLicenseId as LicenseId);
      return;
    }

    // Case 2: Only min license ID exists
    if (!profileLicenseId && minLicenseId) {
      onFlowCompleted(minLicenseId as LicenseId);
      return;
    }

    // Case 3: Both exist - prioritize profile license ID
    if (profileLicenseId && minLicenseId) {
      onFlowCompleted(profileLicenseId as LicenseId);
      return;
    }

    // If neither exists, do nothing
  }, [user]);

  const isCurrentStep = (): boolean => currentStep === CONFIRM_LICENSE_STEP;

  const onFlowCompleted = (licenseId: LicenseId) => {
    setStepCompleted(true);
    setLicenseId(licenseId);
    onStepCompleted(licenseId);
  }

  const getLicenseName = (): string => {
    switch (licenseId) {
      case 1:
        return 'Individual';
      case 2:
        return 'Startup';
      case 3:
      case 4:
      case 5:
      case 6:
        return 'Enterprise';
      default:
        return;
    }
  }

  const renderTitle = (): React.ReactElement => {
    return (
      <div className="flex w-full">
        <span className={`inter text-21 leading-24 font-bold ${!isCurrentStep() && !stepCompleted ? 'text-[#4c5456]' : 'text-white'}`}>
          {!doNotShowStepOrderNumber && <>{CONFIRM_LICENSE_STEP + 1}.{' '}</>}{customStepTitle || 'License Type'}
        </span>
      </div>
    )
  }

  const renderFlowView = (): React.ReactElement => {
    if (stepCompleted || !isCurrentStep()) return;
    return <LicenseConfirmationMinimalFlow onFlowCompleted={onFlowCompleted} />
  }

  const renderLicenseConfirmedView = (): React.ReactElement => {
    if (!stepCompleted) return;
    return (
      <div className="flex gap-2.5 max-md:px-3 max-md:py-6 md:p-6 items-center border-[1px] border-solid border-grey-70 bg-gray-800 text-16 leading-24 font-normal inter rounded-[5px]">
        <LicenseIcon className="w-6 h-6"/>
        <span>
          {getLicenseName()}
        </span>
      </div>
    );
  }

  const renderLicenseUnknownView = (): React.ReactElement => {
    if (stepCompleted || isCurrentStep() || !displayUnknownLicenseUi) return;
    return (
      <div className="opacity-50 flex gap-2.5 max-md:px-3 max-md:py-6 md:p-6 items-center border-[1px] border-solid border-grey-70 bg-gray-800 text-16 leading-24 font-normal inter rounded-[5px]">
        <LicenseIcon className="w-6 h-6"/>
        <span>
          Unknown
        </span>
      </div>
    );
  }

  return (
    <div
      cy-test-id="confirm-license-step"
      className="flex flex-col w-full gap-6"
    >
      {renderTitle()}
      {renderLicenseConfirmedView()}
      {renderFlowView()}
      {renderLicenseUnknownView()}
    </div>
  );
}

const mapStateToProps = (state: ApplicationState): ConfirmLicenseStepStateProps => ({
  user: state.auth?.user as User
});


export default connect(mapStateToProps)(ConfirmLicenseStep);
