import { OnboardingFlowProps, OnboardingFlowStateProps } from '../../types/components/onboarding-flow/OnboardingFlow';
import { ApplicationState } from '../../types/state/storeTypes';
import { User } from '../../types/api/UsersTypes';

import { connect, useDispatch } from 'react-redux';
import { useState, useEffect } from 'react';
import { changeLicenseThunk } from '../../state/actions/cartActions';
import userHelpers from '../../helpers/user';

import LoginOrSignupStep from './steps/LoginOrSignupStep';
import ConfirmLicenseStep from './steps/ConfirmLicenseStep';
import PaymentMethodStep from './steps/PaymentMethodStep';

import {
  LOGIN_OR_SIGNUP_STEP,
  CONFIRM_LICENSE_STEP,
  PAYMENT_METHOD_STEP,
  FIRST_STEP,
  ENTERPRISE_FORM_STEP,
} from './constants';

const OnboardingFlow: React.FC<OnboardingFlowProps> = ({
  user,
  cart,
  onPaymentMethodSelected,
  onLicenseSelected,
  onStripePaymentMethodSelected,
  wrapInStripeElements = false,
  doNotShowPaymentMethodStep = false,
  displayUnknownLicenseUi = false,
  doNotShowStepOrderNumbers = false,
  paswordlessSignup = false,
  customStepTitles,
  isEnterpriseFlow = false,
}) => {
  const dispatch = useDispatch();
  const { getMinLicenseId } = userHelpers;
  const [currentStep, setCurrentStep] = useState<number>();

  const updateCartLicense = () => {
    const userLicenseId = getMinLicenseId(user);
    if (!userLicenseId) return;
    dispatch(
      changeLicenseThunk(
        cart.order,
        userLicenseId.toString(),
      )
    );
  }

  const initializeCurrentStepState = () => {
    if (!user?.id) return setCurrentStep(FIRST_STEP);
    const userLicenseId = getMinLicenseId(user);
    updateCartLicense();
    setCurrentStep(
      !!userLicenseId ?
      PAYMENT_METHOD_STEP :
      CONFIRM_LICENSE_STEP
    );
  }

  useEffect(() => {
    initializeCurrentStepState();
  }, [user]);

  const nextStep = () => {
    switch (currentStep) {
      case LOGIN_OR_SIGNUP_STEP:
        setCurrentStep(CONFIRM_LICENSE_STEP);
        return;
      case CONFIRM_LICENSE_STEP:
        if (isEnterpriseFlow) setCurrentStep(ENTERPRISE_FORM_STEP)
        if (doNotShowPaymentMethodStep) return;
        setCurrentStep(PAYMENT_METHOD_STEP);
        return;
      default:
        return;
    }
  }

  return (
    <div
      cy-test-id="onboarding-flow"
      className="w-full flex flex-col gap-[60px] h-fit"
    >
      <LoginOrSignupStep
        onStepCompleted={nextStep}
        currentStep={currentStep}
        doNotShowStepOrderNumber={doNotShowStepOrderNumbers}
        customStepTitle={customStepTitles?.loginOrSignupStep}
        paswordlessSignup={paswordlessSignup}
      />
      <ConfirmLicenseStep
        onStepCompleted={licenseId => {
          nextStep();
          if (!onLicenseSelected) return;
          onLicenseSelected(licenseId);
        }}
        currentStep={currentStep}
        displayUnknownLicenseUi={displayUnknownLicenseUi}
        doNotShowStepOrderNumber={doNotShowStepOrderNumbers}
        customStepTitle={isEnterpriseFlow ? 'Confirm License' :customStepTitles?.confirmLicenseStep}
      />
      {
        !doNotShowPaymentMethodStep &&
        <PaymentMethodStep
          wrapInElements={wrapInStripeElements}
          currentStep={currentStep}
          onPaymentMethodSelected={method => {
            if (!onPaymentMethodSelected) return;
            onPaymentMethodSelected(method);
          }}
          onStripePaymentMethodSelected={stripeMethod => {
            if (!onStripePaymentMethodSelected) return;
            onStripePaymentMethodSelected(stripeMethod);
          }}
          doNotShowStepOrderNumber={doNotShowStepOrderNumbers}
          customStepTitle={customStepTitles?.paymentMethodStep}
        />
      }
    </div>
  );
};

const mapStateToProps = (state: ApplicationState): OnboardingFlowStateProps => ({
  user: state.auth?.user as User,
  cart: state.cart,
});

export default connect(mapStateToProps)(OnboardingFlow);
