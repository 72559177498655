import { SavedCardProps } from '../../types/components/payment/SavedCard';

import { TrashIcon } from '../../modules/Icons';
import CardVendorLogo from '../shared/CardVendorLogo';

import stripeHelpers from '../../helpers/stripe/helpers';

export const selectedCardClasses = 'border-[2px] border-a-blue border-solid bg-[#031922]';
export const notSelectedCardClasses = 'border-[2px] border-gray-1000 bg-gray-1000';

const SavedCard: React.FC<SavedCardProps> = (props: SavedCardProps) => {
  const { card, highlight, onClick, onDelete, testId } = props;
  const { getFormattedCardBrandName } = stripeHelpers;

  const clickHandler = () => {
    if (!onClick) return;
    onClick();
  }

  return (
    <div
      className={`
        text-white pt-6 pr-[20px] pb-[20px] pl-6 md:w-[350px] h-[175px] rounded-[5px]
        ${highlight ? selectedCardClasses : notSelectedCardClasses}
      `}
      cy-test-id={testId || 'saved-card'}
    >
      <div
        className={`
          w-full
          ${onClick ? 'cursor-pointer' : 'cursor-default'}
        `}
        cy-test-id="click-area"
        onClick={clickHandler}
      >
        <span className="flex mb-4">
          <span className="font-bold capitalize inter text-16 leading-24" cy-test-id="brand-name">
            {getFormattedCardBrandName(card)}
          </span>
          <CardVendorLogo
            card={card}
            className="ml-auto"
          />
        </span>
        <span className="block font-semibold inter text-16 leading-24" cy-test-id="last-4">
          **** **** **** {card.last4}
        </span>
        <span className="block font-semibold inter text-16 leading-24" cy-test-id="expiry">
          {card.exp_month}/{card.exp_year}
        </span>
      </div>
      {
        !!onDelete &&
        <div className="flex mt-4" cy-test-id="delete-card-block">
          <TrashIcon
            className="scale-[1.2] z-[49] ml-auto stroke-a-gray hover:stroke-a-blue cursor-pointer"
            onClick={onDelete}
          />
        </div>
      }
    </div>
  );
};

export default SavedCard;
