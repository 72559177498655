import { PaymentMethodStepProps, PaymentMethodStepStateProps } from '../../../types/components/onboarding-flow/steps/PaymentMethodStep';
import { ApplicationState } from '../../../types/state/storeTypes';
import { User } from '../../../types/api/UsersTypes';
import { LicenseId } from '../../../types/api/LicenseTypesTypes';
import { StripePaymentMethodsList } from '../../../types/api/stripe/paymentMethodsList';

import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { loadStripe } from '@stripe/stripe-js';
import { stripeOptions } from '../../../constants/stripe';
import stripeHelpers from '../../../helpers/stripe/helpers';

import { PAYMENT_METHOD_STEP } from '../constants';

import Payment from '../../checkout/Payment';;
import { Elements } from '@stripe/react-stripe-js';

const PaymentMethodStep: React.FC<PaymentMethodStepProps> = ({
  currentStep,
  onPaymentMethodSelected,
  onStripePaymentMethodSelected,
  user,
  doNotShowStepOrderNumber = false,
  customStepTitle,
}) => {
  const isCurrentStep = (): boolean => currentStep === PAYMENT_METHOD_STEP;
  const [stripePaymentMethods, setStripePaymentMethods] = useState<StripePaymentMethodsList>();

  const { getCardsFor } = stripeHelpers;

  const loadSavedCards = async () => {
    try {
      const stripePaymentMethods = await getCardsFor(user) as StripePaymentMethodsList;
      handleStripePaymentMethodsUpdate(stripePaymentMethods);
    } catch (e) {
      // setError(e.message || e.error?.message || 'An error has occured');
    }
  }

  const handleStripePaymentMethodsUpdate = (stripePaymentMethods: StripePaymentMethodsList) => {
    setStripePaymentMethods(stripePaymentMethods);
    const stripePaymentMethod = stripePaymentMethods.data?.length ? stripePaymentMethods.data[0] : undefined;
    onPaymentMethodSelected(stripePaymentMethod ? 'saved_card' : 'card');
    onStripePaymentMethodSelected(stripePaymentMethod);

  }

  useEffect(() => {
    if (!user?.id) return;
    loadSavedCards();
  }, [user]);

  const displayPaymentBlock = (): boolean =>
    isCurrentStep() && !!stripePaymentMethods;

  const renderTitle = (): React.ReactElement => {
    return (
      <div className="flex w-full">
        <span className={`inter text-21 leading-24 font-bold ${!isCurrentStep() ? 'text-[#4c5456]' : 'text-white'}`}>
          {!doNotShowStepOrderNumber && <>{PAYMENT_METHOD_STEP + 1}.{' '}</>}{customStepTitle || 'Payment Method'}
        </span>
      </div>
    )
  }

  return (
    <div
      cy-test-id="payment-method-step"
      className="w-full flex flex-col gap-6"
    >
      {renderTitle()}
      {
        displayPaymentBlock() &&
        <Payment
          purchaseUnderLicenseId={user.min_license_id as LicenseId}
          stripePaymentMethods={stripePaymentMethods}
          onPaymentMethodSelected={onPaymentMethodSelected}
          onStripePaymentMethodSelected={onStripePaymentMethodSelected}
          onStripePaymentMethodsUpdated={handleStripePaymentMethodsUpdate}
          hideTitle={true}
        />
      }
    </div>
  );
}

const container = (props: PaymentMethodStepProps) => {
  if (!props.wrapInElements) return <PaymentMethodStep {...props} />;
  const stripePromise = loadStripe(process.env.STRIPE_PUBLISHABLE_KEY);
  return (
    <Elements
      stripe={stripePromise}
      options={{
        mode: 'setup',
        currency: 'usd',
        appearance: stripeOptions,
        paymentMethodTypes: ['card']
      }}
    >
      <PaymentMethodStep {...props} />
    </Elements>
  );
};

const mapStateToProps = (state: ApplicationState): PaymentMethodStepStateProps => ({
  user: state.auth?.user as User,
});

export default connect(mapStateToProps)(container);
