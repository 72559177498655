import { useRouter } from 'next/router'; 
import { LicenseConfirmationMinimalFlowProps, LicenseConfirmationMinimalFlowStateProps } from '../../types/components/license-confirmation-minimal-flow/LicenseConfirmationMinimalFlow';
import { AnswerConfig } from '../../types/components/license-confirmation-minimal-flow/_general'
import { LicenseId } from '../../types/api/LicenseTypesTypes';
import { User } from '../../types/api/UsersTypes';
import { SubscriptionDetail } from '../../types/api/SubscriptionTypes';
import { ApplicationState } from '../../types/state/storeTypes';

import QuestionStep from './steps/QuestionStep';
import SuggestedLicenseConfirmationStep from './steps/SuggestedLicenseConfirmationStep';
import EnterpriseFormStep from './steps/EnterpriseFormStep';
import { FormProgressBar } from '../shared/FormProgressBar';

import helpers from './helpers';
import axios from 'axios';

import { changeLicenseThunk } from '../../state/actions/cartActions';

import {
  LICENSE_TO_USE_FILES_STEP,
  DOWNLOADED_FILES_ACCESS_STEP,
  SUGGESTED_LICENSE_STEP,
  ENTERPRISE_FORM_STEP,
  ANNUAL_REVENUE_STEP,
  REVENUE_TIER_STEP,
  STEPS_COUNT,
  ENTERPRISE_STEPS_COUNT,
  FIRST_STEP,
  LAST_STEP,
  ENTERPRISE_LAST_STEP,
} from './constants';
import { ENV } from '../../constants/environments';
import { AUTH_ACTIONS } from '../../constants/actions';
const { AUTH_SET_MIN_LICENSE_ID } = AUTH_ACTIONS;

import { useDispatch, connect } from 'react-redux';
import { addMessage } from '../../state/actions/messagesActions';
import { useState } from 'react';

const LicenseConfirmationMinimalFlow: React.FC<LicenseConfirmationMinimalFlowProps> = ({
  onFlowCompleted,
  user,
  subscription,
  authenticated,
  cart,
  isEnterpriseFlow = false
}) => {
  // If isEnterpriseFlow is true, start at REVENUE_TIER_STEP, otherwise start at FIRST_STEP
  const [currentStep, setCurrentStep] = useState<number>(isEnterpriseFlow ? REVENUE_TIER_STEP : FIRST_STEP);
  const [answersStack, setAnswersStack] = useState<AnswerConfig[]>([]);
  const [previousStepsStack, setPreviousStepsStack] = useState<number[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [confirmedLicense, setConfirmedLicense] = useState<LicenseId | null>(null);

  const { calculateSuggestedLicense, getFlowConfigFilteredByLicense } = helpers;

  const apiUrl = ENV.api.baseURL;
  const dispatch = useDispatch();
  const router = useRouter();

  const progressPercentage = (): number => {
    const totalSteps = isEnterpriseFlow ? ENTERPRISE_STEPS_COUNT : STEPS_COUNT;

    if (isEnterpriseFlow) {
      const stepsProgressed = currentStep - REVENUE_TIER_STEP + 1;
      const totalRemainingSteps = ENTERPRISE_STEPS_COUNT - (REVENUE_TIER_STEP - 1);
      return (stepsProgressed / totalRemainingSteps) * 100;
    }

    return (currentStep / totalSteps) * 100;
  }

  const nextStep = (answer: AnswerConfig) => {
    if (!isEnterpriseFlow && currentStep === LAST_STEP) return;
    if (isEnterpriseFlow && currentStep === ENTERPRISE_LAST_STEP) return;

    setAnswersStack([...answersStack, answer]);
    setPreviousStepsStack([...previousStepsStack, currentStep]);
    setCurrentStep(answer.stepIndexAfterAnswering);
  }

  const previousStep = () => {
    if (isEnterpriseFlow && currentStep === REVENUE_TIER_STEP) return;
    if (currentStep === FIRST_STEP) return;

    const answersStackState = [...answersStack];
    answersStackState.pop();
    setAnswersStack(answersStackState);
    const previousStepsStackState = [...previousStepsStack];
    const previousStepIndex = previousStepsStackState.pop();
    setPreviousStepsStack(previousStepsStackState);

    // If we're in enterprise flow, don't allow going back before REVENUE_TIER_STEP
    if (isEnterpriseFlow && previousStepIndex < REVENUE_TIER_STEP) {
      setCurrentStep(REVENUE_TIER_STEP);
    } else {
      setCurrentStep(previousStepIndex);
    }
  }

  const handleEnterpriseFormSubmit = () => {
    // If we already have a confirmed license, complete the flow
    confirmedLicense && confirmLicenseLoggedInUser(confirmedLicense);
  }

  const confirmLicense = (license_id: LicenseId) => {
    setConfirmedLicense(license_id);

    // If we're in enterprise flow, check if user has a team with licensee_profile
    if (isEnterpriseFlow) {
      if (user?.team?.licensee_profile || user?.min_license_id) {
        // Skip the enterprise form step and complete the flow directly
        router.push(`pricing/enterprise?license_id=${license_id}`)
      } else {
        // Proceed to enterprise form step for users without existing licensee profile
        setPreviousStepsStack([...previousStepsStack, currentStep]);
        setCurrentStep(ENTERPRISE_FORM_STEP);
      }
      return;
    }

    // Otherwise process as normal
    confirmLicenseLoggedInUser(license_id) 
  }

  const confirmLicenseLoggedInUser = async (license_id: LicenseId) => {
    setLoading(true);
    try {
      const url = `${apiUrl}/users/update_min_license`;
      const payload = { license_id };
      await axios.post(url, payload, { headers: { authorization: user.token } });
      dispatch({ type: AUTH_SET_MIN_LICENSE_ID, min_license_id: license_id });
      dispatch(
        changeLicenseThunk(
          cart.order,
          license_id.toString(),
        )
      );
      setLoading(false);
      if (onFlowCompleted) onFlowCompleted(license_id);
    }
    catch (e) {
      dispatch(addMessage({ body: e?.message || 'An error has occurred', type: 'error' }));
      setLoading(false);
    }
  }

  const renderCurrentStep = (): React.ReactElement => {
    const _flowConfig = getFlowConfigFilteredByLicense(user, subscription);
    const stepConfig = _flowConfig.find(step => step.stepIndex === currentStep);

    switch (currentStep) {
      case LICENSE_TO_USE_FILES_STEP:
      case DOWNLOADED_FILES_ACCESS_STEP:
      case ANNUAL_REVENUE_STEP:
      case REVENUE_TIER_STEP:
        return (
          <QuestionStep
            question={stepConfig.stepQuestionConfig}
            onAnswerSelected={nextStep}
            onBack={currentStep === FIRST_STEP ? undefined : previousStep}
          />
        );
      case SUGGESTED_LICENSE_STEP:
        const suggestedLicenseId = calculateSuggestedLicense(answersStack);
        return (
          <SuggestedLicenseConfirmationStep
            suggestedLicenseId={suggestedLicenseId}
            onBack={previousStep}
            onConfirmed={confirmLicense}
            disableNextButton={loading}
          />
        );
      case ENTERPRISE_FORM_STEP:
        return (
          <EnterpriseFormStep
            onSubmit={handleEnterpriseFormSubmit}
            onBack={previousStep}
            disableSubmitButton={loading}
            confirmedLicense={confirmedLicense}
          />
        );
      default:
        return <div>Invalid step</div>;
    };
  }

  return (
    <div
      cy-test-id="license-confirmation-minimal-flow"
      className="flex flex-col w-full gap-5 py-5"
    >
      <FormProgressBar
        bgColor="bg-gray-700"
        className="bg-a-blue !h-[4px]"
        progressPct={progressPercentage()}
        cornered={true}
      />
      {renderCurrentStep()}
    </div>
  );
}

const mapStateToProps = (state: ApplicationState): LicenseConfirmationMinimalFlowStateProps => ({
  user: state.auth.user as User,
  authenticated: state.auth.authenticated,
  subscription: state.auth.subscription as SubscriptionDetail,
  cart: state.cart,
  isEnterpriseFlow: state.onboardingModal?.isEnterpriseFlow
});

export default connect(mapStateToProps)(LicenseConfirmationMinimalFlow);