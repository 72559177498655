import { AnswerConfig } from '../../types/components/license-confirmation-minimal-flow/_general'
import { LicenseId } from '../../types/api/LicenseTypesTypes';
import { StepConfig } from '../../types/components/license-confirmation-minimal-flow/_general';
import { User } from '../../types/api/UsersTypes';
import { SubscriptionDetail } from '../../types/api/SubscriptionTypes';

import deepCopy from '../../utils/deepCopy';

import {
  DOWNLOADED_FILES_ACCESS_ANSWER_INDIVIDUAL_ID,
  DOWNLOADED_FILES_ACCESS_ANSWER_STARTUP_ID,
  ANNUAL_REVENUE_ANSWER_STARTUP_ID,
  REVENUE_TIER_ANSWER_1_ID,
  REVENUE_TIER_ANSWER_2_ID,
  REVENUE_TIER_ANSWER_3_ID,
  REVENUE_TIER_ANSWER_4_ID,
  flowConfig,
} from './constants';

const calculateSuggestedLicense = (answersStack: Array<AnswerConfig>): LicenseId => {
  if (!answersStack?.length || answersStack?.length < 1) return;
  const lastAnswer = answersStack[answersStack.length - 1];
  switch (lastAnswer.answerId) {
    case DOWNLOADED_FILES_ACCESS_ANSWER_INDIVIDUAL_ID:
      return 1;
    case DOWNLOADED_FILES_ACCESS_ANSWER_STARTUP_ID:
    case ANNUAL_REVENUE_ANSWER_STARTUP_ID:
      return 2;
    case REVENUE_TIER_ANSWER_1_ID:
      return 3;
    case REVENUE_TIER_ANSWER_2_ID:
      return 4;
    case REVENUE_TIER_ANSWER_3_ID:
      return 5;
    case REVENUE_TIER_ANSWER_4_ID:
      return 6;
    default:
      return;
  };
};

// TODO refactor and join logic with subscriptionUpgradeHelpers
const calculateMinLicenseId = (
  user?: User,
  subscription?: SubscriptionDetail,
): LicenseId => {
  let minLicenseIdFromUserLicenseId: LicenseId = (user?.min_license_id || 1) as LicenseId;
  let minLicenseIdFromUserTeam: LicenseId = 1;
  let minLicenseIdFromSubscription: LicenseId;

  if (user?.team?.licensee_profile) {
    const { completed, invalidated } = user?.team?.licensee_profile;
    if (completed && !invalidated) {
      minLicenseIdFromUserTeam = user.team?.licensee_profile?.license_type.id as LicenseId;
    }
  }

  const licenseIdsToFactorIn = [
    minLicenseIdFromUserLicenseId,
    minLicenseIdFromUserTeam,
  ];

  const returnValue = (values: Array<LicenseId>): LicenseId => {
    let max = Math.max(...values);
    return max as LicenseId;
  }

  if (!subscription) return returnValue(licenseIdsToFactorIn);

  minLicenseIdFromSubscription = subscription.license.type.id as LicenseId;
  return returnValue([ ...licenseIdsToFactorIn, minLicenseIdFromSubscription ]);

}

// TODO test
const getFlowConfigFilteredByLicense = (
  user?: User,
  subscription?: SubscriptionDetail,
): Array<StepConfig> => {
  const _flowConfig: Array<StepConfig> = deepCopy(flowConfig);
  const minLicenseId = calculateMinLicenseId(user, subscription);
  _flowConfig.forEach(step => {
    if (!step.stepQuestionConfig) return;
    step.stepQuestionConfig.answers = step.stepQuestionConfig.answers.filter(
      answer => answer.licenseIdsImpliedByAnswer.some(licenseId => licenseId >= minLicenseId)
    );
  });
  return _flowConfig;
}

const helpers = {
  calculateSuggestedLicense,
  getFlowConfigFilteredByLicense,
};

export default helpers;
