import { LoginOrSignupStepProps, LoginOrSignupStepStateProps } from '../../../types/components/onboarding-flow/steps/LoginOrSignupStep';
import { ApplicationState } from '../../../types/state/storeTypes';
import { User } from '../../../types/api/UsersTypes';

import SignupForm from '../../shared/SignupForm';
import LoginForm from '../../shared/LoginForm';

import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { signOut } from 'next-auth/react';

import { LOGIN_OR_SIGNUP_STEP } from '../constants';

const LoginOrSignupStep: React.FC<LoginOrSignupStepProps> = ({
  currentStep,
  onStepCompleted,
  user,
  doNotShowStepOrderNumber = false,
  paswordlessSignup = false,
  customStepTitle,
}) => {
  const [mode, setMode] = useState<'login' | 'signup'>('signup');
  const [stepCompleted, setStepCompleted] = useState<boolean>(false);

  useEffect(() => {
    if (!user?.id) return;
    // if we have a user in the state, that means
    // that we're logged in, so this step is completed
    setStepCompleted(true);
    onStepCompleted();
  }, [user]);

  const getTitle = (): string => {
    if (customStepTitle) return customStepTitle;
    if (!isCurrentStep()) return 'Account';
    switch (mode) {
      case 'login': return 'Log in';
      case 'signup': return 'Create Account';
      default: return;
    }
  }

  const isCurrentStep = (): boolean => currentStep === LOGIN_OR_SIGNUP_STEP;

  const toggleMode = () => {
    // Simply toggle the mode without dispatching any actions
    setMode(mode === 'login' ? 'signup' : 'login');
  }

  const renderToggle = (): React.ReactElement | null => {
    if (!isCurrentStep() || stepCompleted) return null;
    switch (mode) {
      case 'login':
        return (
          <span className="ml-auto font-normal text-14 leading-24 inter">
            <span className="text-white">
              Don't have an account?
            </span>
            {' '}
            <span className="cursor-pointer text-a-blue" onClick={toggleMode}>
              Create
            </span>
          </span>
        );
      case 'signup':
        return (
          <span className="ml-auto font-normal text-14 leading-24 inter">
            <span className="text-white">
              Existing user?
            </span>
            {' '}
            <span className="cursor-pointer text-a-blue" onClick={toggleMode}>
              Log In
            </span>
          </span>
        );
      default: return null;
    }
  }

  const renderTitle = (): React.ReactElement => {
    return (
      <div className="flex w-full">
        <span className="font-bold text-white inter text-21 leading-24">
          {!doNotShowStepOrderNumber && <>{LOGIN_OR_SIGNUP_STEP + 1}.{' '}</>}{getTitle()}
        </span>
        {renderToggle()}
      </div>
    )
  }

  const renderForm = (): React.ReactElement | null => {
    if (!!user?.id) return null;
    return (
      <>
        {
          mode === 'signup' &&
          <div className="flex flex-col gap2">
            <SignupForm
              isOnboardingFlow={true}
              paswordlessSignup={paswordlessSignup}
              minimalView={true}
              customFormClass="w-full flex flex-col gap-6"
              customButtonClass="py-3 px-6 rounded-[5px] bg-a-blue text-white eurostile text-17 leading-24 uppercase font-bold w-[245px]"
              customButtonWording="Confirm & continue"
            />
            <span className="font-normal text-white inter text-14 leading-22">
              We'll create an account for you and send instructions to the email above to confirm your account.
            </span>
          </div>
        }
        {
          mode === 'login' &&
          <LoginForm
            isOnboardingFlow={true}
            submitBtnText={'Continue'}
            minimalView={true}
            customFormClass="w-full flex flex-col gap-6"
            customButtonClass="py-3 px-6 rounded-[5px] bg-a-blue text-white eurostile text-17 leading-24 uppercase font-bold w-[245px]"
          />
        }
      </>
    );
  }

  const renderLoggedInView = (): React.ReactElement | null => {
    if (!user?.id) return null;
    return (
      <div className="flex max-md:px-3 max-md:py-6 md:p-6 border-[1px] border-solid border-grey-70 bg-gray-800 text-16 leading-24 font-normal inter rounded-[5px]">
        <span className="flex flex-col md:flex-row md:gap-1.5">
          <span className="text-white">
            You are logged in as
          </span>
          <span className="text-a-blue">
            {user.email}
          </span>
        </span>
        <span className="ml-auto text-a-blue text-[11px] md:text-[13px] underline cursor-pointer" onClick={() => signOut()}>
          Logout
        </span>
      </div>
    )
  }

  return (
    <div
      cy-test-id="login-or-signup-step"
      className="flex flex-col w-full gap-6"
    >
      {renderTitle()}
      {renderForm()}
      {renderLoggedInView()}
    </div>
  )
};

const mapStateToProps = (state: ApplicationState): LoginOrSignupStepStateProps => ({
  user: state.auth?.user as User,
});

export default connect(mapStateToProps)(LoginOrSignupStep);