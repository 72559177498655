import { PaymentProps } from '../../types/components/checkout/Payment';
import { StripePaymentMethodData } from '../../types/api/stripe/paymentMethod';
import { PaymentMethod } from '../../types/ui/paymentMethod';
import { StripePaymentMethodsList } from '../../types/api/stripe/paymentMethodsList';
import { StripeConfirmIntentResponse } from '../../types/api/stripe/confirmIntentResponse';

import PaymentForm from '../payment/PaymentForm';
import ChangePaymentMethodModal from './ChangePaymentMethodModal';
import { CheckoutPanel } from '../../modules/Panels';

import { useState } from 'react';

const Payment: React.FC<PaymentProps> = (props: PaymentProps) => {
  const {
    stripePaymentMethods: _stripePaymentMethods,
    onStripePaymentMethodsUpdated,
    onPaymentMethodSelected,
    onStripePaymentMethodSelected,
    onStripeIntentConfrimed,
    purchaseUnderLicenseId,
    hideTitle,
  } = props;

  const [stripePaymentMethods, setPaymentMethods] = useState<StripePaymentMethodsList>(_stripePaymentMethods);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<PaymentMethod>('card');
  const [selectedStripePaymentMethod, setSelectedStripePaymentMethod] = useState<StripePaymentMethodData>(stripePaymentMethods?.data[0]);
  const [showChangePaymentMethodModal, setShowChangePaymentMethodModal] = useState(false);

  // TODO test
  const stripeConfirmIntentResponseCallback = (response: StripeConfirmIntentResponse) => {
    if (onStripeIntentConfrimed) onStripeIntentConfrimed(response);
  }

  const paymethMethodChangedCallback = (paymentMethod: PaymentMethod) => {
    setSelectedPaymentMethod(paymentMethod);
    if (onPaymentMethodSelected) onPaymentMethodSelected(paymentMethod);
  }

  const stripePaymentMethodsUpdateCallback = (newPaymentMethods: StripePaymentMethodsList) => {
    setSelectedStripePaymentMethod(newPaymentMethods.data[0]);
    setPaymentMethods(newPaymentMethods);
    if (onStripePaymentMethodsUpdated) onStripePaymentMethodsUpdated(newPaymentMethods);
  }

  const stripePaymentMethodSelectCallback = (paymentMethod: StripePaymentMethodData) => {
    setSelectedStripePaymentMethod(paymentMethod);
    setSelectedPaymentMethod('saved_card');
    if (onStripePaymentMethodSelected) onStripePaymentMethodSelected(paymentMethod);
    if (onPaymentMethodSelected) onPaymentMethodSelected('saved_card');
  }

  const changePaymentMethod = () => {
    setShowChangePaymentMethodModal(true);
  }

  const renderPaymentMethodForm = (): React.ReactElement => {
    if (stripePaymentMethods?.data?.length) return;
    return (
      <PaymentForm
        onPaymentMethodSelected={paymethMethodChangedCallback}
        defaultPaymentMethod={selectedPaymentMethod}
        purchaseUnderLicenseId={purchaseUnderLicenseId}
      />
    );
  }

  const renderCardSelectionBlock = (): React.ReactElement => {
    if (!stripePaymentMethods?.data?.length) return;
    const panelContent = (
      <>
        {
          selectedPaymentMethod !== 'paypal' &&
          <>
            <span className="text-[14px] md:text-[18px] font-bold leading-24 text-white capitalize">{selectedStripePaymentMethod.card.brand}</span>
            <span className="ml-2 text-[14px] md:text-16 font-semibold leading-24 text-white">**** **** **** {selectedStripePaymentMethod.card.last4}</span>
          </>
        }
        {
          selectedPaymentMethod === 'paypal' &&
          <span className="text-[18px] font-bold leading-24 text-white capitalize">PayPal</span>
        }
        <a
          className="text-a-blue underline text-[11px] md:text-[13px] cursor-pointer ml-auto"
          cy-test-id="change-payment-method-link"
          onClick={changePaymentMethod}
        >
          Change
        </a>
      </>
    );
    return <CheckoutPanel children={panelContent} testId="saved-card-selection" />
  };

  const renderChangePaymentMethodModal = (): React.ReactElement => {
    if (!stripePaymentMethods?.data?.length) return;
    return (
      <ChangePaymentMethodModal
        isOpen={showChangePaymentMethodModal}
        setIsOpen={setShowChangePaymentMethodModal}
        stripePaymentMethods={stripePaymentMethods}
        onStripePaymentMethodsUpdated={stripePaymentMethodsUpdateCallback}
        onStripePaymentMethodSelected={stripePaymentMethodSelectCallback}
        onStripeIntentConfrimed={stripeConfirmIntentResponseCallback}
        selectedStripePaymentMethod={selectedStripePaymentMethod}
        onPaymentMethodSelected={paymethMethodChangedCallback}
      />
    );
  }

  return (
    <div className="w-full" cy-test-id="payment">
      {
        !hideTitle &&
        <h1 className="text-white text-[21px] inter font-bold leading-24 mb-[21px]">
          Payment Method
        </h1>
      }
      {renderCardSelectionBlock()}
      {renderPaymentMethodForm()}
      {renderChangePaymentMethodModal()}
    </div>
  );
}

export default Payment;
