import { StepConfig } from '../../types/components/license-confirmation-minimal-flow/_general';

export const LICENSE_TO_USE_FILES_STEP = 0;
export const DOWNLOADED_FILES_ACCESS_STEP = 1;
export const ANNUAL_REVENUE_STEP = 2;
export const REVENUE_TIER_STEP = 3;
export const SUGGESTED_LICENSE_STEP = 4;
export const ENTERPRISE_FORM_STEP = 5;

export const STEPS_COUNT = 5;
export const FIRST_STEP = LICENSE_TO_USE_FILES_STEP;
export const LAST_STEP = SUGGESTED_LICENSE_STEP;

export const ENTERPRISE_LAST_STEP = ENTERPRISE_FORM_STEP;
export const ENTERPRISE_STEPS_COUNT = 6;

export const LICENSE_TO_USE_FILES_QUESTION = 'Who will be licensed to use the files downloaded from ActionVFX?';
export const LICENSE_TO_USE_FILES_ANSWER_INDIVIDUAL_ID = 0;
export const LICENSE_TO_USE_FILES_ANSWER_INDIVIDUAL = 'Only me, an individual';
export const LICENSE_TO_USE_FILES_ANSWER_STARTUP_ID = 1;
export const LICENSE_TO_USE_FILES_ANSWER_STARTUP = 'Someone else at my organization';
export const LICENSE_TO_USE_FILES_ANSWER_ENTERPRISE_ID = 2;
export const LICENSE_TO_USE_FILES_ANSWER_ENTERPRISE = 'Multiple artists at my organization';

export const DOWNLOADED_FILES_ACCESS_QUESTION = 'Will the files downloaded from ActionVFX be accessible to more than one person through shared storage spaces or project files?';
export const DOWNLOADED_FILES_ACCESS_ANSWER_INDIVIDUAL_ID = 3;
export const DOWNLOADED_FILES_ACCESS_ANSWER_INDIVIDUAL = 'No, only I will have access to the files';
export const DOWNLOADED_FILES_ACCESS_ANSWER_STARTUP_ID = 4;
export const DOWNLOADED_FILES_ACCESS_ANSWER_STARTUP = 'Yes, other members of the team may have access';

export const ANNUAL_REVENUE_QUESTION = 'How much does your company make in revenue or funding per year?';
export const ANNUAL_REVENUE_ANSWER_STARTUP_ID = 5;
export const ANNUAL_REVENUE_ANSWER_STARTUP = 'Under $3 Million USD';
export const ANNUAL_REVENUE_ANSWER_ENTERPRISE_ID = 6;
export const ANNUAL_REVENUE_ANSWER_ENTERPRISE = 'At least $3 Million USD';

export const REVENUE_TIER_QUESTION = 'What\'s your total annual revenue/funding?';
export const REVENUE_TIER_ANSWER_1_ID = 7;
export const REVENUE_TIER_ANSWER_1 = '$3M-$20M USD';
export const REVENUE_TIER_ANSWER_2_ID = 8;
export const REVENUE_TIER_ANSWER_2 = '$21M-$49M USD';
export const REVENUE_TIER_ANSWER_3_ID = 9;
export const REVENUE_TIER_ANSWER_3 = '$50M-$99M USD';
export const REVENUE_TIER_ANSWER_4_ID = 10;
export const REVENUE_TIER_ANSWER_4 = 'Over $100M USD';

export const flowConfig: Array<StepConfig> = [
  {
    stepIndex: LICENSE_TO_USE_FILES_STEP,
    stepQuestionConfig: {
      questionText: LICENSE_TO_USE_FILES_QUESTION,
      answers: [
        {
          answerId: LICENSE_TO_USE_FILES_ANSWER_INDIVIDUAL_ID,
          answerText: LICENSE_TO_USE_FILES_ANSWER_INDIVIDUAL,
          stepIndexAfterAnswering: DOWNLOADED_FILES_ACCESS_STEP,
          licenseIdsImpliedByAnswer: [1],
        },
        {
          answerId: LICENSE_TO_USE_FILES_ANSWER_STARTUP_ID,
          answerText: LICENSE_TO_USE_FILES_ANSWER_STARTUP,
          stepIndexAfterAnswering: ANNUAL_REVENUE_STEP,
          licenseIdsImpliedByAnswer: [2],
        },
        {
          answerId: LICENSE_TO_USE_FILES_ANSWER_ENTERPRISE_ID,
          answerText: LICENSE_TO_USE_FILES_ANSWER_ENTERPRISE,
          stepIndexAfterAnswering: ANNUAL_REVENUE_STEP,
          licenseIdsImpliedByAnswer: [3, 4, 5, 6],
        },
      ],
    },
  },
  {
    stepIndex: DOWNLOADED_FILES_ACCESS_STEP,
    stepQuestionConfig: {
      questionText: DOWNLOADED_FILES_ACCESS_QUESTION,
      answers: [
        {
          answerId: DOWNLOADED_FILES_ACCESS_ANSWER_INDIVIDUAL_ID,
          answerText: DOWNLOADED_FILES_ACCESS_ANSWER_INDIVIDUAL,
          stepIndexAfterAnswering: SUGGESTED_LICENSE_STEP,
          licenseIdsImpliedByAnswer: [1],
        },
        {
          answerId: DOWNLOADED_FILES_ACCESS_ANSWER_STARTUP_ID,
          answerText: DOWNLOADED_FILES_ACCESS_ANSWER_STARTUP,
          stepIndexAfterAnswering: ANNUAL_REVENUE_STEP,
          licenseIdsImpliedByAnswer: [2],
        },
      ],
    },
  },
  {
    stepIndex: ANNUAL_REVENUE_STEP,
    stepQuestionConfig: {
      questionText: ANNUAL_REVENUE_QUESTION,
      answers: [
        {
          answerId: ANNUAL_REVENUE_ANSWER_STARTUP_ID,
          answerText: ANNUAL_REVENUE_ANSWER_STARTUP,
          stepIndexAfterAnswering: SUGGESTED_LICENSE_STEP,
          licenseIdsImpliedByAnswer: [2],
        },
        {
          answerId: ANNUAL_REVENUE_ANSWER_ENTERPRISE_ID,
          answerText: ANNUAL_REVENUE_ANSWER_ENTERPRISE,
          stepIndexAfterAnswering: REVENUE_TIER_STEP,
          licenseIdsImpliedByAnswer: [3, 4, 5, 6],
        },
      ],
    },
  },
  {
    stepIndex: REVENUE_TIER_STEP,
    stepQuestionConfig: {
      questionText: REVENUE_TIER_QUESTION,
      answers: [
        {
          answerId: REVENUE_TIER_ANSWER_1_ID,
          answerText: REVENUE_TIER_ANSWER_1,
          stepIndexAfterAnswering: SUGGESTED_LICENSE_STEP,
          licenseIdsImpliedByAnswer: [3],
        },
        {
          answerId: REVENUE_TIER_ANSWER_2_ID,
          answerText: REVENUE_TIER_ANSWER_2,
          stepIndexAfterAnswering: SUGGESTED_LICENSE_STEP,
          licenseIdsImpliedByAnswer: [4],
        },
        {
          answerId: REVENUE_TIER_ANSWER_3_ID,
          answerText: REVENUE_TIER_ANSWER_3,
          stepIndexAfterAnswering: SUGGESTED_LICENSE_STEP,
          licenseIdsImpliedByAnswer: [5],
        },
        {
          answerId: REVENUE_TIER_ANSWER_4_ID,
          answerText: REVENUE_TIER_ANSWER_4,
          stepIndexAfterAnswering: SUGGESTED_LICENSE_STEP,
          licenseIdsImpliedByAnswer: [6],
        },
      ],
    },
  },
  {
    stepIndex: SUGGESTED_LICENSE_STEP,
  },
];

export const licenseDisqualifications: { [key: number]: Array<string> } = {
  1: [
    "You're a studio, agency, or other types of team comprised of more than 1 person",
    "You're a contractor working inside a studio (the studio will need their own license)"
  ],
  2: [
    "Your company's revenue or funding is higher than $3 Million USD per year",
    'You need your license to cover multiple companies and subsidiaries'
  ],
  3: [],
};
