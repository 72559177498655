import { useState, useEffect } from 'react';
import { useElements } from '@stripe/react-stripe-js';
import { useStripe } from '@stripe/react-stripe-js';
import { connect, useDispatch } from 'react-redux';
import { setMessages } from '../../state/actions/messagesActions';
import stripeHelpers from '../../helpers/stripe/helpers';

import {
  ChangePaymentMethodModalProps,
  ChangePaymentMethodModalStateProps
} from '../../types/components/checkout/ChangePaymentMethodModal';
import { StripePaymentMethodData } from '../../types/api/stripe/paymentMethod';
import { StripePaymentMethodsList } from '../../types/api/stripe/paymentMethodsList';
import { PaymentMethod } from '../../types/ui/paymentMethod';
import { User } from '../../types/api/UsersTypes';
import { ApplicationState } from '../../types/state/storeTypes';
import { AxiosError } from 'axios';
import { StripeConfirmIntentResponse } from '../../types/api/stripe/confirmIntentResponse';

import { Dialog } from '@headlessui/react';
import { NewExitIcon } from '../../modules/Icons';
import SavedCard from '../payment/SavedCard';
import PaymentForm from '../payment/PaymentForm';
import { MainLinkButton } from '../styled/buttons/MainLinkButton';
import YesNoPromptDialog from '../shared/YesNoPromptDialog';
import { Loader } from '../shared/Loader';

const ChangePaymentMethodModal: React.FC<ChangePaymentMethodModalProps> = (
  props: ChangePaymentMethodModalProps
) => {
  const {
    isOpen,
    setIsOpen,
    stripePaymentMethods: _stripePaymentMethods,
    user,
    onStripePaymentMethodsUpdated,
    onStripePaymentMethodSelected,
    onPaymentMethodSelected,
    // TODO test
    onStripeIntentConfrimed,
    selectedStripePaymentMethod
  } = props;
  const {
    saveNewCardFor,
    setSelectedCardFor,
    getCardsFor,
    deletePaymentMethod,
    getFormattedCardBrandName
  } = stripeHelpers;
  const elements = useElements();
  const stripe = useStripe();
  const dispatch = useDispatch();

  const [stripePaymentMethods, setStripePaymentMethods] = useState<StripePaymentMethodsList>();
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<StripePaymentMethodData>();
  const [selectedNewPaymentMethodType, setSelectedNewPaymentMethodType] =
    useState<PaymentMethod>('card');
  const [loading, setLoading] = useState(false);
  const [paymentMethodToDelete, setPaymentMethodToDelete] = useState<StripePaymentMethodData>();
  const [displayDeleteMethodConfirmDialog, setDisplayDeleteMethodConfirmDialog] = useState(false);

  useEffect(() => {
    setStripePaymentMethods(_stripePaymentMethods);
  }, [_stripePaymentMethods]);

  useEffect(() => {
    setSelectedPaymentMethod(selectedStripePaymentMethod);
  }, [selectedStripePaymentMethod]);

  const resetAndCloseModal = () => {
    setIsOpen(false);
    setSelectedPaymentMethod(undefined);
    setSelectedNewPaymentMethodType('card');
    setLoading(false);
    setPaymentMethodToDelete(undefined);
    setDisplayDeleteMethodConfirmDialog(false);
  };

  const paymentMethodSelectedCallback = (method: PaymentMethod) => {
    setSelectedNewPaymentMethodType(method);
    if (onPaymentMethodSelected) onPaymentMethodSelected(method);
    if (method === 'paypal') resetAndCloseModal();
  };

  const reloadSavedStripePaymentMethods = async () => {
    try {
      const cards = await getCardsFor(user);
      setStripePaymentMethods(cards as StripePaymentMethodsList);
      if (onStripePaymentMethodsUpdated)
        onStripePaymentMethodsUpdated(cards as StripePaymentMethodsList);
    } catch (e) {
      errorHandler(e);
    }
  };

  const onStripePaymentMethodSelectionSuccess = async (
    isNewPaymentMethod: boolean,
    confirmIntentData?: StripeConfirmIntentResponse,
    savedPaymentMethod?: StripePaymentMethodData
  ) => {
    if (onStripeIntentConfrimed) onStripeIntentConfrimed(confirmIntentData);
    if (isNewPaymentMethod) {
      await reloadSavedStripePaymentMethods();
    } else if (!!onStripePaymentMethodSelected) {
      onStripePaymentMethodSelected(savedPaymentMethod);
    }
    resetAndCloseModal();
  };

  const errorHandler = (result: AxiosError) => {
    setLoading(false);
    dispatch(
      setMessages([
        {
          type: 'error',
          body: result.message
        }
      ])
    );
  };

  const saveNewPaymentMethod = () => {
    switch (selectedNewPaymentMethodType) {
      case 'card':
        return saveCard();
      default:
        return;
    }
  };

  const saveCard = async () => {
    if (loading) return;
    setLoading(true);
    const submitElementsResult = await elements.submit();
    if (submitElementsResult.error) {
      setLoading(false);
      return;
    }
    await saveNewCardFor(
      user,
      (result: StripeConfirmIntentResponse) => onStripePaymentMethodSelectionSuccess(true, result),
      errorHandler,
      stripe,
      elements
    );
  };

  const selectSavedPaymentMethod = async (paymentMethod: StripePaymentMethodData) => {
    if (loading) return;
    setLoading(true);
    setSelectedPaymentMethod(paymentMethod);
    await setSelectedCardFor(
      user,
      paymentMethod,
      (result: StripeConfirmIntentResponse) =>
        onStripePaymentMethodSelectionSuccess(false, result, paymentMethod),
      errorHandler,
      stripe
    );
  };

  const onDeleteStripePaymentMethodClick = (paymentMethod: StripePaymentMethodData) => {
    setPaymentMethodToDelete(paymentMethod);
    setDisplayDeleteMethodConfirmDialog(true);
  };

  const onDeleteStripePaymentMethodDialogResult = async (result: boolean) => {
    if (!result) {
      setPaymentMethodToDelete(undefined);
      setDisplayDeleteMethodConfirmDialog(false);
      return;
    }
    try {
      await deletePaymentMethod(paymentMethodToDelete);
      setPaymentMethodToDelete(undefined);
      reloadSavedStripePaymentMethods();
    } catch (e) {
      errorHandler(e);
    }
  };

  const renderSelectStripePaymentMethodBlock = (): React.ReactElement => {
    return (
      <div className="block w-full" cy-test-id="select-payment-method-block">
        <h2 className="mb-6 font-bold text-white inter text-16 leading-24">Saved Cards</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-[6px] max-h-[200px] overflow-auto noscrollbar">
          {stripePaymentMethods?.data.map((paymentMethod, index) => (
            <SavedCard
              key={index}
              card={paymentMethod.card}
              highlight={paymentMethod.id === selectedPaymentMethod?.id}
              onClick={() => selectSavedPaymentMethod(paymentMethod)}
              onDelete={() => onDeleteStripePaymentMethodClick(paymentMethod)}
              testId={`saved-card-${paymentMethod.card.last4}`}
            />
          ))}
        </div>
      </div>
    );
  };

  const renderNewPaymentMethodBlock = (): React.ReactElement => {
    return (
      <div className="block w-full mt-8" cy-test-id="new-payment-method-block">
        <h2 className="mb-6 font-bold text-white inter text-16 leading-24">Add New</h2>
        <PaymentForm
          onPaymentMethodSelected={paymentMethodSelectedCallback}
          defaultPaymentMethod={selectedNewPaymentMethodType}
        />
        <MainLinkButton
          className="uppercase !w-full md:!w-[300px] !h-[48px] mt-[44px]"
          variant="blue"
          onClick={saveNewPaymentMethod}
          disabled={loading}
          testId="confirm-new-payment-method">
          confirm
        </MainLinkButton>
      </div>
    );
  };

  return (
    <>
      <Dialog open={isOpen} onClose={() => setIsOpen(false)} className="relative z-50">
        <div className="fixed inset-0 overflow-y-auto flex items-center justify-center bg-black/60 z-[99]">
          <Dialog.Panel className="max-md:h-[100vh]">
            <div
              className="w-full md:w-[800px] md:max-h-[100vh] md:overflow-y-auto bg-a-dark-gray shadow-lg drop-shadow-lg rounded-[5px] py-[30px] pl-[40px] pr-[30px]"
              cy-test-id="change-payment-method-modal">
              <div className="flex mb-[30px]">
                <h1
                  className="text-white inter text-[21px] font-bold leading-24"
                  cy-test-id="title">
                  Choose Payment Method
                </h1>
                <NewExitIcon
                  className="ml-auto cursor-pointer !stroke-[#F5F5F5] opacity-[50%] w-[20px] h-[20px]"
                  onClick={resetAndCloseModal}
                />
              </div>
              {renderSelectStripePaymentMethodBlock()}
              {renderNewPaymentMethodBlock()}
            </div>
          </Dialog.Panel>
        </div>
      </Dialog>
      {paymentMethodToDelete && (
        <YesNoPromptDialog
          dialogOpen={displayDeleteMethodConfirmDialog}
          onResult={onDeleteStripePaymentMethodDialogResult}
          promptText={`Are you sure you want to delete this card (Details: ${getFormattedCardBrandName(
            paymentMethodToDelete?.card
          ).toUpperCase()}, ends in ${paymentMethodToDelete?.card?.last4})?`}
        />
      )}
      {loading && <Loader />}
    </>
  );
};

const mapStateToProps = (state: ApplicationState): ChangePaymentMethodModalStateProps => ({
  user: state.auth.user as User
});

export default connect(mapStateToProps)(ChangePaymentMethodModal);
