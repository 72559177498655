import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../../../types/state/storeTypes';
import { EnterpriseFormStepProps, EnterpriseFormStepStateProps } from '../../../types/components/license-confirmation-minimal-flow/EnterpriseFormStep';
import { EnterpriseLicenseeProfileFormData, EnterpriseLicenseeProfileFormErrors } from '../../../types/components/forms/Forms';
import { User } from '../../../types/api/UsersTypes';
import { BasicInput } from '../../../modules/Inputs';
import { CheckmarkButton } from '../../styled/buttons/CheckmarkButton';
import { createLicenseProfile } from '../../../helpers/licenseProfileHelpers';
import ButtonsBlock from '../shared/ButtonsBlock';

const EnterpriseFormStep: React.FC<EnterpriseFormStepProps> = ({
  onSubmit,
  onBack,
  disableSubmitButton = false,
  user,
  confirmedLicense
}) => {
  const [formData, setFormData] = useState<EnterpriseLicenseeProfileFormData>({
    company_name: '',
    email: '',
    phone: '',
    addressLine1: '',
    addressLine2: '',
    city: '',
    state: '',
    zip: '',
    country: ''
  });

  const requiredFields: (keyof EnterpriseLicenseeProfileFormData)[] = [
    'company_name',
    'email',
    'phone',
    'addressLine1',
    'city',
    'state',
    'zip',
    'country'
  ];

  const [isFormValid, setIsFormValid] = useState(false);
  const [errors, setErrors] = useState<EnterpriseLicenseeProfileFormErrors>({});
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [userAgreement, setUserAgreement] = useState(false);

  const validateForm = () => {
    const newErrors: EnterpriseLicenseeProfileFormErrors = {};
    requiredFields.forEach((field) => {
      if (!formData[field] || String(formData[field]).trim() === '') {
        newErrors[field] = 'This field is required';
      }
    });

    if (formData.email) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(formData.email)) {
        newErrors.email = 'Invalid email address';
      }
    }

    if (!userAgreement) {
      newErrors.userAgreement = 'You must agree to the terms';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  useEffect(() => {
    const isValid = validateForm();
    setIsFormValid(isValid);
  }, [formData, userAgreement]);

  const handleInputChange = (name: keyof EnterpriseLicenseeProfileFormData, value: string) => {
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleLicenseProfileSubmission = async () => {
    const isValid = validateForm();
    if (!isValid || !userAgreement) {
      if (!userAgreement) {
        setErrors((prev) => ({ ...prev, userAgreement: 'You must agree to the terms' }));
      }
      return;
    }

    setIsLoading(true);
    try {
      const addressComponents = [
        formData.addressLine1,
        formData.addressLine2,
        formData.city,
        formData.state,
        formData.zip,
        formData.country
      ];

      const combinedAddress = addressComponents.filter(ac => !!ac?.length).join(', ');

      const { addressLine1, addressLine2, ...restFormData } = formData;
      const submissionData = {
        ...restFormData,
        address: combinedAddress,
        license_id: confirmedLicense,
        first_name: '',
        last_name: ''
      };

      try {
        await createLicenseProfile(user, submissionData);

        onSubmit();
        setIsSubmitted(true);
      } catch (innerError) {
        console.error("Error in API call:", innerError);
        throw innerError;
      }
    } catch (error) {
      console.error('Error submitting license profile:', error);
      setErrors(prev => ({
        ...prev,
        submission: "An error occurred during submission. Please try again later."
      }));
    }
  };

  const renderInput = (
    name: keyof EnterpriseLicenseeProfileFormData,
    label: string,
    placeholder: string,
    type: string = 'text'
  ) => {
    return (
      <div className="flex flex-col w-full">
        <BasicInput
          type={type}
          label={`${label}*`}
          placeholder={placeholder}
          name={name}
          value={''}
          onChange={(e) => handleInputChange(name, e.target.value)}
          className={`mt-1 block w-full !font-light ${isSubmitted && errors[name] ? '!border rounded-[5px] !border-red-500' : ''
            }`}
        />
        {isSubmitted && errors[name] && <p className="mt-1 text-xs text-red-500">This field is required</p>}
      </div>
    );
  };

  function handleUserAgreement() {
    setUserAgreement(!userAgreement);
  }

  const renderEnterpriseFormContent = () => (
    <>
      <p className="text-[16px] text-white">Licensee Name</p>
      <p className="flex gap-2 text-[14px] font-light text-gray-600 mb-[18px]">
        The name of the entity licensed to use the products purchased.{' '}
      </p>
      <p className="text-[16px] text-white">Contact Information</p>
      <p className="flex gap-2 text-[14px] font-light text-gray-600 mb-[24px]">
        Email and phone number of the point of contact to discuss all licensing matters.{' '}
      </p>
      <p className="text-[16px] text-white">Licensee Address</p>
      <p className="flex gap-2 text-[14px] font-light text-gray-600 mb-[24px]">
        The physical address of the licensee.{' '}
      </p>
    </>
  );

  const renderEnterpriseFormInputs = () => (
    <>
      <div className="mb-8">
        {renderInput('company_name', 'Company name', 'Business Legal Name')}
      </div>
      <div className='flex justify-between gap-4 lg:gap-2'>
        <div className="w-full mb-5">{renderInput('email', 'Contact email', 'Contact Email')}</div>
        <div className="w-full mb-10">{renderInput('phone', 'Contact phone', 'Phone Number', 'tel')}</div>
      </div>
    </>
  );

  return (
    <div cy-test-id="enterprise-form-step" className="flex flex-col gap-4">
      <div className="mt-[48px] mb-[40px] lg:flex lg:flex-row gap-[48px]">
        <div className="flex-col lg:w-1/3">{renderEnterpriseFormContent()}</div>

        <div className="lg:w-2/3">

          {renderEnterpriseFormInputs()}

          <div className="flex flex-col gap-[16px]">
            {renderInput('addressLine1', 'Address line 1', 'Address Line 1')}
            {renderInput('addressLine2', 'Address line 2', 'Address Line 2')}
            <div className="flex flex-col gap-4 lg:flex-row lg:gap-2">
              {renderInput('city', 'City', 'City')}
              {renderInput('state', 'State', 'State')}
              {renderInput('zip', 'Zip', 'Zip')}
            </div>
            {renderInput('country', 'Country', 'Country')}
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-2">
        <div className="flex gap-2 text-[14px] font-light text-gray-500">
          <CheckmarkButton onClick={handleUserAgreement} checked={userAgreement} /> I certify that
          the information I've entered is correct, and I acknowledge that my license will be voided
          if false.
        </div>
        {isSubmitted && errors.userAgreement && (
          <p className="text-xs text-red-500">{errors.userAgreement}</p>
        )}
      </div>
      <div className="flex justify-end gap-4">
        <ButtonsBlock
          onNext={handleLicenseProfileSubmission}
          nextDisabled={isLoading || !isFormValid || !userAgreement || disableSubmitButton}
          onBack={onBack}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state: ApplicationState): EnterpriseFormStepStateProps => ({
  user: state.auth.user as User
});

export default connect(mapStateToProps)(EnterpriseFormStep);