import { ButtonsBlockProps } from '../../../types/components/license-confirmation-minimal-flow/shared/ButtonsBlock';

import { MainLinkButton } from '../../styled/buttons/MainLinkButton';

const ButtonsBlock: React.FC<ButtonsBlockProps> = ({
  onNext,
  nextDisabled,
  onBack,
  nextButtonText,
}) => {
  return (
    <div
      cy-test-id="buttons-holder"
      className="w-full flex gap-4"
    >
      {
        !!onBack &&
        <MainLinkButton
          className="!h-[48px] text-[17px] bg-white bg-opacity-[10%] hover:bg-white hover:bg-opacity-[20%] text-white"
          testId="back-button"
          onClick={onBack}
        >
          Back
        </MainLinkButton>
      }
      <MainLinkButton
        variant="blue"
        className={`
          !h-[48px] text-[17px]
          ${nextDisabled
          ? 'bg-a-blue bg-opacity-[60%] !hover:bg-a-blue !hover:bg-opacity-[60%] !text-gray-600 cursor-not-allowed'
          : 'bg-a-blue !hover:bg-[#00B74F] !cursor-pointer'}
        `}
        onClick={onNext}
        disabled={nextDisabled}
        testId="next-button"
      >
        {nextButtonText || 'Next'}
      </MainLinkButton>
    </div>
  );
}

export default ButtonsBlock;
