import React, { useEffect, useState } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { TopNavProps, TopNavStateProps } from '../types/layout/TopNavTypes';
import {
  Logo,
  SearchIcon,
  PersonIcon,
  ExitIcon,
  DownChevronIcon,
  NavDivider,
  NewExitIcon,
  CartIcon,
} from '../modules/Icons';
import { connect } from 'react-redux';
import { ApplicationState } from '../types/state/storeTypes';
import { SubscriptionDetail } from '../types/api/SubscriptionTypes';
import { User } from '../types/api/UsersTypes';
import { LicenseId } from '../types/api/LicenseTypesTypes';
import NoSSR from 'react-no-ssr';
import LoginForm from '../components/shared/LoginForm';
import { AUTH_ACTIONS, DOWNLOAD_STATUS_ACTIONS } from '../constants/actions';
import SignupForm from '../components/shared/SignupForm';
import Messages from '../components/shared/Messages';
import { signOut } from 'next-auth/react';
import { ProductSearch } from '../components/products/ProductSearch';
import MobileNav from './MobileNav';
import { TopNavigationAssets, TopNavigationLearn } from '../staticData/Navigation';
import UserMenu from '../components/shared/UserMenu';
import RemainingCreditsIndicator from '../components/nav/RemainingCreditsIndicator';
import BuyMoreCreditsDialog from '../components/credits/BuyMoreCreditsDialog';
import SubscriptionUpgradeModal from '../components/subscription-upgrade-modal/SubscriptionUpgradeModal';
import CTAButton from '../components/nav/CTAButton';
import LicenseConfirmationFlow from '../components/license-confirmation-modals/LicenseConfirmationFlow';
import { addMessage } from '../state/actions/messagesActions';
import { hideUpdateLicenseModal } from '../state/actions/licenseFlowActions';
import { showModal } from '../state/actions/buyMoreCreditsActions';
import { openCartAction } from '../state/actions/cartActions';
import DownloadLimitModal from '../components/downloads/DownloadLimitModal';
import DownloadLimit4K from '../components/downloads/DownloadLimitModal4K';
import subscriptionHelpers from '../helpers/subscriptionHelpers';
import nonSubscriptionPurchaseHelpers from '../helpers/nonSubscriptionPurchase';
import cartHelpers from '../helpers/cart';
import UserNotifications from '../components/shared/UserNotifications';
import ChurnkeyFailedPaymentWall from '../components/shared/ChurnkeyFailedPaymentWall';
import UrlParamsHandler from '../components/helpers/UrlParamsHandler';
import ShoppingCart from './ShoppingCart';
import OnboardingFlowModal from '../components/onboarding-flow-modal/OnboardingFlowModal';

const { AUTH_SHOW_LOGIN, AUTH_HIDE_LOGIN, AUTH_HIDE_SIGN_UP } = AUTH_ACTIONS;

const TopNav: React.FC<TopNavProps> = (props: TopNavProps) => {
  const { cart, showLogin, showSignUp, messages, user_notifications, auth, showUpdateLicense, dispatch, subscription, user } = props;
  const { isCreditBasedLegacy } = subscriptionHelpers;
  const { cartUiVisible } = nonSubscriptionPurchaseHelpers;
  const { filterOutDiscountItem } = cartHelpers;
  const router = useRouter();
  const totalProducts = cart.order !== undefined ? Object.values(cart.order.products).filter(filterOutDiscountItem).length : 0;

  const [activeMenu, setActiveMenu] = useState(null);
  const [searchActive, setSearchActive] = useState(false);
  const [mobileNavOpen, setMobileNavOpen] = useState(false);
  const [showLowCreditsBanner, setShowLowCreditsBanner] = useState(false);

  const [selectedItem, setSelectedItem] = useState(TopNavigationAssets[0]);
  const [selectedLibraryId, setSelectedLibraryId] = useState(TopNavigationAssets[0].libraryId);

  const linkClasses = 'hover:text-a-blue text-white whitespace-nowrap cursor-pointer';
  const linkCallback = () => {
    props.dispatch({ type: AUTH_HIDE_LOGIN });
    props.dispatch({ type: AUTH_HIDE_SIGN_UP });
    return true;
  };

  const handleLockedUser = () => {
    if (!(auth?.user as User)?.locked) return;
    props.dispatch(
      addMessage({
        type: 'error',
        body: 'Your account is currently locked. To regain access, please contact our team at support@actionvfx.com.'
      })
    );
    signOut();
  }

  const handleBuyMoreCreditsAutoOpen = () => {
    if (
      router.pathname === '/pricing' &&
      router.query?.credit_pack === 'true' &&
      !!auth?.subscription &&
      !isCreditBasedLegacy(auth?.subscription as SubscriptionDetail)
    ) dispatch(showModal('buy-more-credits'));
    setTimeout(() => {
      if (
        router.pathname === '/pricing' &&
        !!router.query?.credit_pack
      ) router.replace('/pricing', undefined, { shallow: true });
    }, 1000);
  }

  useEffect(() => {
    handleLockedUser();
    handleBuyMoreCreditsAutoOpen();
  }, [auth?.user, subscription?.current_credit_balance]);

  const closeUpdateLicenseFlowModal = () => {
    props.dispatch(hideUpdateLicenseModal());
  }

  const getUserFirstName = (): string => auth?.user?.first_name || '';

  const getUserLastName = (): string => auth?.user?.last_name || '';

  return (
    <>
      <header aria-label="Site Header" className="fixed z-40 w-full bg-a-darker-gray ju_stoppush">
        <div className="px-4 mx-auto w sm:px-6 lg:px-8">
          <div className="flex h-[70px] items-center justify-between">
            <div className={`md:flex md:items-center md:gap-12 ${searchActive ? 'hidden' : ''}`}>
              <Link className="block" href="/">
                <span className="sr-only">Home</span>
                <Logo className="w-[148px] sm:w-48 lg:mr-[-15px] lg:ml-[-15px] xl:ml-[-20px] xl:mr-[-12px] 2xl:ml-[-55px] 4k:ml-[-100px] lg:w-40 2xl:w-72 4k:w-96" />
              </Link>
            </div>

            {searchActive && (
              <div className={`w-full md:w-6/12 lg:w-8/12 md:pr-2 relative ml-1 ${searchActive ? 'flex' : 'hidden'}`}>
                <ProductSearch
                  customId="search-bar"
                  selectedClass="text-white"
                  chevronClass="stroke-[#F5F5F5]"
                  homePage={false}
                  dropdownAdjustments="bg-a-dark-gray mt-[3px] w-[214px] 2xl:min-w-[230px] text-[14px] 2xl:text-[16px] leading-[24px]"
                  searchResultsDropdown="overflow-y-auto max-h-[calc(100vh-50px)] bg-a-dark-gray"
                  resultsOverlayClass="w-screen h-screen"
                  setSelectedLibraryId={setSelectedLibraryId}
                  selectedLibraryId={selectedLibraryId}
                  selectedItem={selectedItem}
                  setSelectedItem={setSelectedItem}
                  searchActive={searchActive}
                />
                <div
                  className="flex justify-center items-center sm:w-[24px] sm:h-[24px] rounded-full absolute top-[14px] sm:top-[8px] right-4 sm:right-8 md:right-5 cursor-pointer"
                  onClick={() => {
                    setSearchActive(false);
                  }}>
                  <NewExitIcon className="stroke-white" />
                </div>
              </div>
            )}

            <div className={`${searchActive ? 'hidden' : 'hidden lg:block'}`}>
              <nav aria-label="Site Nav">
                <ul className={`flex items-center uppercase text-[12px] 2xl:text-[14px] font-[500] tracking-[32] gap-1 ${auth.authenticated !== true && 'lg:ml-4'} ${auth.authenticated === true && 'md:gap-6 xl:gap-3 2xl:gap-6'} md:gap-4 lg:gap-2 xl:gap-4 2xl:gap-6 4k:gap-8`}>
                  {TopNavigationAssets.map((item, index) => (
                    <li
                      key={index}
                      onMouseEnter={() => setActiveMenu(item.text)}
                      onMouseLeave={() => setActiveMenu(null)}>
                      <Link
                        className={`${linkClasses} flex items-center gap-2`}
                        href={item.href || '#'}
                        onClick={linkCallback}>
                        <div className="flex gap-2">
                          <div className="items-center hidden lg:flex 2xl:flex">{item.icon}</div>
                          {item.text}{' '}
                        </div>
                      </Link>
                    </li>
                  ))}

                  <section className='hidden lg:flex'>
                    <NavDivider />
                  </section>

                  {TopNavigationLearn.map((item, index) => (
                    <li
                      key={index}
                      onMouseEnter={() => setActiveMenu(item.text)}
                      onMouseLeave={() => setActiveMenu(null)}
                      className="hidden xl:flex 2xl:gap-8 4k:gap-16">
                      <Link
                        className={`${linkClasses} hover:text-a-blue flex items-center lg:ml-1 `}
                        href={item.href || '#'}
                        onClick={linkCallback}>
                        {item.icon}
                        {item.text}{' '}
                        {item.children?.length > 0 ? (
                          <DownChevronIcon className={`inline ${auth.authenticated !== true && '!mr-0'} ml-2 mr-2`} stroke="white" />
                        ) : null}
                      </Link>

                      {item.children?.length > 0 && (
                        <ul
                          className={`${activeMenu == item.text ? 'flex' : 'hidden'
                            } absolute top-11 bg-a-darker-gray p-4 pt-6 flex-col gap-4 rounded-[5px] drop-shadow-md`}>
                          {item.children.map((subitem, j) => (
                            <li className="pt-2" key={j}>
                              <Link
                                className={`${linkClasses} fill-white hover:fill-a-blue`}
                                href={subitem.href}
                                onClick={linkCallback}>
                                {subitem.icon} {subitem.text}
                              </Link>
                            </li>
                          ))}
                        </ul>
                      )}
                    </li>
                  ))}
                  <li>
                    <Link
                      className={`${linkClasses} ${router.pathname.includes('pricing') ? 'text-a-blue' : 'text-white'
                        } hidden lg:flex`}
                      href="/pricing"
                      onClick={linkCallback}>
                      Pricing
                    </Link>
                  </li>
                </ul>
              </nav>
            </div>

            <div className="flex items-center gap-2 lg:gap-1 lg:mr-6 2xl:mr-10">
              <div className="flex gap-.5">
                <div className="flex gap-.5">
                  <button
                    className={`${searchActive
                      ? 'hidden'
                      : 'flex rounded-[5px] items-center text-white whitespace-nowrap lg:bg-a-dark-gray px-3 py-1 md:mr-3'
                      }`}
                    onClick={() => {
                      setSearchActive(true);
                    }}>
                    <SearchIcon className="flex items-center w-[18px] h-[18px] sm:w-[20px] sm:h-[20px] stroke-white" />
                  </button>
                  {
                    !!cartUiVisible(router) &&
                    <Link
                      className="rounded-[5px] sm:px-4 md:px-3 lg:px-4 sm:py-2 mr-2 lg:bg-a-dark-gray text-white text-[14px] whitespace-nowrap"
                      href="#cart"
                      onClick={() => props.dispatch(openCartAction())}>
                      <div className="flex items-center h-full md:mr-[-10px] lg:mr-0">
                        <CartIcon className="inline stroke-white" />
                        {totalProducts > 0 && (
                          <div className="ml-[4px] bg-a-blue text-white px-2 rounded-[5px]">
                            {totalProducts}
                          </div>
                        )}
                      </div>
                    </Link>
                  }
                </div>

                <div className={`${searchActive && 'hidden md:flex'}`}>
                  <CTAButton />
                  <RemainingCreditsIndicator />
                </div>

                <div className="items-center hidden gap-2 max-md: md:flex md:gap-4">
                  {auth.authenticated === undefined && (
                    // placeholder until session loaded
                    <div
                      className="ml-2 rounded-full w-[40px] h-[40px] bg-a-gray"
                      cy-test-id="auth-loading-placeholder"></div>
                  )}

                  {!auth.authenticated && auth.authenticated !== undefined && (
                    <>
                      <Link
                        id="login-button"
                        className="hidden lg:flex items-center justify-center gap-2 w-[107px] h-[40px] rounded-[5px] text-white font-[500] text-[16px] leading-[22px] bg-a-darker-gray py-[10px] mr-[-50px]"
                        href="#sign-in"
                        onClick={() => props.dispatch({ type: AUTH_SHOW_LOGIN })}>
                        <PersonIcon className="fill-white" />
                        <div>Sign In</div>
                      </Link>
                    </>
                  )}

                  {auth.authenticated && (
                    <div
                      onMouseEnter={() => setActiveMenu('profile')}
                      onMouseLeave={() => setActiveMenu(null)}
                      id="nav-user-profile-wrapper">
                      <Link
                        className="ml-2 rounded-full w-[40px] h-[40px] bg-white text-a-dark-gray text-[16px] font-medium flex justify-center items-center tracking-tight"
                        href="/accounts/profile">
                        {getUserFirstName().length ? getUserFirstName()[0] : ''}
                        {getUserLastName().length ? getUserLastName()[0] : ''}
                      </Link>
                      <div
                        className={`${activeMenu == 'profile' ? 'block' : 'hidden'
                          } absolute ml-[-160px] w-[240px]`}>
                        <UserMenu linkCallback={linkCallback} />
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="z-10 block lg:hidden md:pl-4">
                <button
                  onClick={() => {
                    setMobileNavOpen(!mobileNavOpen);
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                  }}
                  className="p-2 text-gray-600 transition bg-gray-100 rounded hover:text-gray-600/75 dark:bg-gray-800 dark:text-white dark:hover:text-white/75">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-5 h-5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth="2">
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M4 6h16M4 12h16M4 18h16"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
      </header>
      <div className="h-[70px]"></div>
      <div
        className={
          (showLogin ? 'block' : 'hidden') +
          ' bg-a-dark-gray py-24 shadow-xl shadow-[rgba(0,0,0,0.25)] fixed w-full z-50 top-[70px] overflow-y-auto max-h-[calc(100vh-70px)]'
        }>
        <Link
          href="#"
          onClick={() => props.dispatch({ type: AUTH_HIDE_LOGIN })}
          className="absolute right-8 top-6">
          <ExitIcon />
        </Link>
        <LoginForm submitBtnText="sign in" />
      </div>

      <div
        className={
          (showSignUp ? 'block' : 'hidden') +
          ' bg-a-dark-gray py-24 shadow-xl shadow-[rgba(0,0,0,0.25)] fixed w-full z-50 top-[70px] overflow-y-auto max-h-[calc(100vh-70px)]'
        }>
        <Link
          href="#"
          onClick={() => props.dispatch({ type: AUTH_HIDE_SIGN_UP })}
          className="absolute right-8 top-6">
          <ExitIcon />
        </Link>
        <SignupForm />
      </div>

      <ShoppingCart />
      <UserNotifications notifications={user_notifications} />
      <Messages messages={messages} />
      <UrlParamsHandler />

      {mobileNavOpen && (
        <MobileNav
          auth={auth}
          router={router}
          signOut={signOut}
          dispatch={props.dispatch}
          setMobileNavOpen={setMobileNavOpen}
          mobileNavOpen={mobileNavOpen}
          subscription={subscription}
        />
      )}

      {props.auth.subscription && <SubscriptionUpgradeModal />}
      <BuyMoreCreditsDialog
        licenseId={((auth.subscription as SubscriptionDetail)?.license.id || 1) as LicenseId}
      />
      <LicenseConfirmationFlow open={showUpdateLicense} onClose={closeUpdateLicenseFlowModal} isUpdate={false} />
      <DownloadLimitModal />
      <DownloadLimit4K />
      <ChurnkeyFailedPaymentWall />
      <OnboardingFlowModal />
    </>
  );
};

const mapStateToProps = (state: ApplicationState): TopNavStateProps => ({
  authenticated: state.auth.authenticated,
  showLogin: state.auth.showLogin,
  showSignUp: state.auth.showSignUp,
  showUpdateLicense: state.licenseFlowReducer.showUpdateLicenseModal,
  downloadStatus: state.downloadStatus,
  user: state.auth.user,
  user_notifications: state.auth.user_notifications,
  messages: state.messages.messages,
  auth: state.auth,
  subscription: state.auth.subscription as SubscriptionDetail,
  cart: state.cart,
});

const ConnectedTopNav = connect(mapStateToProps)(TopNav);

export default (props) => (
  <NoSSR>
    <ConnectedTopNav {...props} />
  </NoSSR>
);
