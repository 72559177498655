import { QuestionStepProps } from '../../../types/components/license-confirmation-minimal-flow/steps/QuestionStep';
import { AnswerConfig } from '../../../types/components/license-confirmation-minimal-flow/_general';

import { useState } from 'react';

import Answer from '../shared/Answer';
import ButtonsBlock from '../shared/ButtonsBlock';

const QuestionStep: React.FC<QuestionStepProps> = ({
  question,
  onAnswerSelected,
  onBack,
}) => {
  const [selectedAnswer, setSelectedAnswer] = useState<AnswerConfig>();

  const onNext = () => {
    if (!selectedAnswer) return;
    onAnswerSelected(selectedAnswer);
  }

  return (
    <div
      cy-test-id="question-step"
      className="w-full flex flex-col gap-6"
    >
      <span
        className="text-white text-18 font-medium leading-28"
        cy-test-id="question-text"
      >
        {question.questionText}
      </span>
      <div
        cy-test-id="answers-wrapper"
        className="w-full flex flex-col gap-3"
      >
        {question.answers.map((answer, index) => (
          <Answer
            answer={answer}
            onAnswerSelected={setSelectedAnswer}
            selectedAnswer={selectedAnswer}
            key={index}
          />
        ))}
      </div>
      <ButtonsBlock
        onNext={onNext}
        nextDisabled={!selectedAnswer}
        onBack={onBack}
      />
    </div>
  )
};

export default QuestionStep;
