import { User } from '../types/api/UsersTypes';
import { GenericResponse } from '../types/api/Http';
import { SubscriptionDetail } from '../types/api/SubscriptionTypes';
import { LicenseId } from '../types/api/LicenseTypesTypes';

import axios from 'axios';

import { ENV } from '../constants/environments';
const apiUrl = ENV.api.baseURL;

export function userIsNotConfirmed(
  user: { id: number, confirmed_at: null | string}
): boolean {
  if (!user) return false;
  return !!user.id && !user.confirmed_at;
}

export function userIsConfirmed(
  user: { id: number, confirmed_at: null | string}
): boolean {
  if (!user) return false;
  return !!user.id && !!user.confirmed_at;
}

export function userIsLocked(
  user: { id: number, locked?: boolean}
): boolean {
  if (!user) return false;
  return !!user.id && user.locked;
}

export function userNotLoggedIn(
  user: { id: number }
): boolean {
  if (!user) return true;
  return !user.id
}

const shouldSeeConfirmLicenseFlow = (user: User): boolean => {
  if (!user) return false;
  return user.team?.licensee_profile?.invalidated || !user.team?.licensee_profile || !user.team?.licensee_profile.completed;
}

const loadUserInfo = async (user: User): Promise<{ user: User, subscription: SubscriptionDetail }> => {
  const profileRes: GenericResponse<{ data: { user: User, subscription: SubscriptionDetail } }> = await axios.get(apiUrl + '/users/me', {
    headers: { authorization: user.token }
  });
  return profileRes?.data?.data;
}

const getLicenseeName = (user: User): string => {
  if (!user?.team?.licensee_profile) return;
  const licensee_profile = user.team.licensee_profile;
  if (licensee_profile.company_name) return licensee_profile.company_name;
  if (licensee_profile.first_name) return `${licensee_profile.first_name} ${licensee_profile.last_name}`;
  return licensee_profile.email;
}

// TODO test
const getMinLicenseId = (user: User): LicenseId => {
  const licenseeProfile = user.team?.licensee_profile;
  if (!!licenseeProfile && !licenseeProfile?.invalidated)
    return licenseeProfile.license_id;
  return user.min_license_id as LicenseId;
}

const isSubscriber = (user: User, subscription: SubscriptionDetail): boolean => !!subscription || user.free_subscriber;

const userHelpers = {
  shouldSeeConfirmLicenseFlow,
  loadUserInfo,
  getLicenseeName,
  isSubscriber,
  getMinLicenseId,
};

export default userHelpers;
