import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../../types/state/storeTypes';
import { User } from '../../types/api/UsersTypes';
import { LicenseFormData, LicneseFormErrors } from '../../types/components/forms/Forms';


import { MainLinkButton } from '../styled/buttons/MainLinkButton';
import { BasicInput } from '../../modules/Inputs';
import { LicenseFormProps } from '../../types/components/license-profile/LicenseFormProps';
import { formTypeConfig } from './LicenseConfigurations';
import { CheckmarkButton } from '../styled/buttons/CheckmarkButton';
import {
  individualFormInputs,
  startupFormInputs,
  enterpriseFormInputs,
  individualFormContent,
  startupFormContent,
  enterpriseFormContent
} from './LicenseFormContent';
import { updateLicenseProfile, createLicenseProfile } from '../../helpers/licenseProfileHelpers';

const LicenseForm: React.FC<LicenseFormProps> = ({
  onChange,
  onNext,
  onBack,
  flowState,
  isUpdate,
  user,
  setConfirmLicenseContent,
}) => {
  const [formType, setFormType] = useState((flowState?.suggestedLicenseType || '').toLowerCase());
  const [formData, setFormData] = useState<LicenseFormData>({
    company_name: '',
    email: '',
    addressLine1: '',
    addressLine2: '',
    city: '',
    state: '',
    zip: '',
    country: ''
  });
  const [requiredFields, setRequiredFields] = useState<(keyof LicenseFormData)[]>(
    () => formTypeConfig[formType].required
  );
  const [isFormValid, setIsFormValid] = useState(false);
  const [errors, setErrors] = useState<LicneseFormErrors>({});
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [userAgreement, setUserAgreement] = useState(false);

  useEffect(() => {
    const lowerCaseFormType = formType.toLowerCase();
    if (formTypeConfig[lowerCaseFormType]) {
      setFormData(prevData => {
        const newData = { ...prevData };
        // Only update fields that exist in the config and aren't undefined
        Object.entries(formTypeConfig[lowerCaseFormType].formData).forEach(([key, value]) => {
          if (value !== undefined) {
            newData[key] = value;
          }
        });
        return newData;
      });
      setRequiredFields(formTypeConfig[lowerCaseFormType].required);
    } else {
      console.error('Invalid form type');
    }
  }, [formType]);

  const validateForm = () => {
    const newErrors: LicneseFormErrors = {};
    requiredFields.forEach((field) => {
      if (!formData[field] || formData[field].trim() === '') {
        newErrors[field] = 'This field is required';
      }
    });

    if (formData.email) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(formData.email)) {
        newErrors.email = 'Invalid email address';
      }
    }

    if (!userAgreement) {
      newErrors.userAgreement = 'You must agree to the terms';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  useEffect(() => {
    const isValid = validateForm();
    setIsFormValid(isValid);
  }, [formData, userAgreement]);

  const handleInputChange = (name: keyof LicenseFormData, value: string) => {
    setFormData((prevData) => ({ ...prevData, [name]: value }));
    onChange(name, value);
  };

  const handleLicenseProfileSubmission = async () => {
    setIsLoading(true);
    try {
      const addressComponents = [
        formData.addressLine1,
        formData.addressLine2,
        formData.city,
        formData.state,
        formData.zip,
        formData.country
      ];

      const combinedAddress = addressComponents.filter(ac => !!ac?.length).join(', ');

      const { addressLine1, addressLine2, ...restFormData } = formData;
      const submissionData = {
        ...restFormData,
        address: combinedAddress,
        license_id: flowState.licenseId
      };

      let result: any;

      if (isUpdate) {
        result = await updateLicenseProfile(user, submissionData);
      } else {
        result = await createLicenseProfile(user, submissionData);
      }

      setConfirmLicenseContent((prevContent) => {
        const updatedContent = {
          ...prevContent,
          'License ID' : result.token,
          'Licensed to':
            formType === 'individual'
              ? `${submissionData.first_name} ${submissionData.last_name}`
              : submissionData.company_name,
          'Contact Person': `${submissionData.first_name} ${submissionData.last_name}`,
          'Contact Email': submissionData.email,
          Address: `${submissionData.address}`,
          ConfirmedLicenseType: formType,
        };

        return updatedContent;
      });

      onNext();
    } catch (error) {
      console.error('Error submitting license profile:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = () => {
    setIsSubmitted(true);
    const isValid = validateForm();
    if (isValid && userAgreement) {
      handleLicenseProfileSubmission();
    } else if (!userAgreement) {
      setErrors((prev) => ({ ...prev, userAgreement: 'You must agree to the terms' }));
    }
  };

  const renderInput = (
    name: keyof LicenseFormData,
    label: string,
    placeholder: string,
    type: string = 'text'
  ) => (
    <div className="flex flex-col w-full">
      <BasicInput
        type={type}
        label={`${label}*`}
        placeholder={placeholder}
        name={name}
        value={formData[name] ?? ''}
        onChange={(e) => handleInputChange(name, e.target.value)}
        className={`mt-1 block w-full !font-light ${
          isSubmitted && errors[name] ? '!border rounded-[5px] !border-red-500' : ''
        }`}
      />
      {isSubmitted && errors[name] && <p className="mt-1 text-xs text-red-500">This field is required</p>}
    </div>
  );

  function renderFormInputs() {
    switch (formType.toLowerCase()) {
      case 'individual':
        return individualFormInputs({ renderInput });
      case 'startup':
        return startupFormInputs({ renderInput });
      case 'enterprise':
        return enterpriseFormInputs({ renderInput });
      default:
        return null;
    }
  }

  function renderFormContent() {
    switch (formType.toLowerCase()) {
      case 'individual':
        return individualFormContent();
      case 'startup':
        return startupFormContent();
      case 'enterprise':
        return enterpriseFormContent();
      default:
        return null;
    }
  }

  function handleUserAgreement() {
    setUserAgreement(!userAgreement);
  }

  return (
    <div cy-test-id="license-form" className="flex flex-col gap-4">
      <div className="mt-[56px] mb-[45px] lg:flex lg:flex-row gap-[48px]">
        <div className="flex-col lg:w-1/3">{renderFormContent()}</div>

        <div className="lg:w-2/3">
          {renderFormInputs()}

          <div className="flex flex-col gap-[16px] mt-[32px]">
            {renderInput('addressLine1', 'Address line 1', 'Address Line 1')}
            {renderInput('addressLine2', 'Address line 2', 'Address Line 2')}
            <div className="flex flex-col gap-4 lg:flex-row lg:gap-2">
              {renderInput('city', 'City', 'City')}
              {renderInput('state', 'State', 'State')}
              {renderInput('zip', 'Zip', 'Zip')}
            </div>
            {renderInput('country', 'Country', 'Country')}
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-2">
        <div className="flex gap-2 text-[14px] font-light text-gray-500">
          <CheckmarkButton onClick={handleUserAgreement} checked={userAgreement} /> I certify that
          the information I've entered is correct, and I acknowledge that my license will be voided
          if false.
        </div>
        {isSubmitted && errors.userAgreement && (
          <p className="text-xs text-red-500">{errors.userAgreement}</p>
        )}
      </div>
      <div className="flex justify-end gap-4">
        <MainLinkButton
          type="submit"
          className="!w-[301px] bg-white bg-opacity-[10%] hover:bg-white hover:bg-opacity-[20%] text-white"
          onClick={onBack}>
          Back
        </MainLinkButton>
        <MainLinkButton
          type="submit"
          variant="blue"
          className={`!w-[447px] ${
            isLoading || !isFormValid || !userAgreement
              ? 'bg-a-blue bg-opacity-[60%] hover:bg-a-blue hover:bg-opacity-[60%] !text-gray-600 cursor-not-allowed'
              : 'bg-a-blue hover:!bg-[#00B74F] !cursor-pointer'
          }`}
          onClick={handleSubmit}
          disabled={isLoading}>
          {isLoading ? 'Submitting...' : 'Submit'}
        </MainLinkButton>
      </div>
    </div>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  user: state.auth.user as User
});

export default connect(mapStateToProps)(LicenseForm);
