import { PaymentMethodProps } from '../../types/components/payment/PaymentMethod';
import { AddressElement, PaymentElement } from '@stripe/react-stripe-js';

import { useSelector } from 'react-redux';
const PaymentMethodComponent = (props: PaymentMethodProps) => {
  const { selectedPaymentMethod } = props;
  const user = useSelector((state: any) => state?.auth?.user);

  return (
    <div className="w-full" cy-test-id="payment-method-component">
      { selectedPaymentMethod === 'card' &&
        <fieldset className="mt-10" cy-test-id="card-element">
          {/* Don't show until user has authed */}
          { user?.first_name &&
            <AddressElement options={{
              mode: 'billing',
              defaultValues: {
                name: user.first_name + ' ' + user.last_name
              }
            }} />
          }

          <PaymentElement />
        </fieldset>
      }
      { selectedPaymentMethod === 'paypal' && (
        <div className="method-block text-gray-500 text-[15px] pt-10" cy-test-id="paypal-element">
          <p>Click "Continue to Paypal" to proceed.</p>
        </div>
      )}
    </div>
  )
}

export default PaymentMethodComponent;
