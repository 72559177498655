import { AnswerProps } from '../../../types/components/license-confirmation-minimal-flow/shared/Answer';

import { SelectedCheck } from '../../../modules/Icons';

export const selectedAnswerClass = 'border-a-blue bg-[#00A1E0] bg-opacity-[8%] text-white';
export const deselectedAnswerdClass = 'border-[#393e41] bg-gray-800 text-a-gray hover:bg-[#00A1E0] hover:bg-opacity-[8%] hover:border-a-blue';

const Answer: React.FC<AnswerProps> = ({
  answer,
  onAnswerSelected,
  selectedAnswer,
}) => {
  const isSelected = (): boolean => selectedAnswer?.answerId === answer.answerId;

  return (
    <div
      cy-test-id={`answer-${answer.answerId}`}
      className={`h-[52px] items-center border-[1px] border-solid w-full py-2.5 px-6 flex rounded-[5px] cursor-pointer ${isSelected() ? selectedAnswerClass : deselectedAnswerdClass}`}
      onClick={() => onAnswerSelected(answer)}
    >
      <span className="inter text-16 leading-24" cy-test-id="answer-text">
        {answer.answerText}
      </span>
      {isSelected() && <SelectedCheck className="ml-auto"/>}
    </div>
  )
};

export default Answer;
