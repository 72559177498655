import { SuggestedLicenseConfirmationStepProps } from '../../../types/components/license-confirmation-minimal-flow/steps/SuggestedLicenseConfirmationStep';

import ButtonsBlock from '../shared/ButtonsBlock';
import LicenseDescription from '../shared/LicenseDescription';

const SuggestedLicenseConfirmationStep: React.FC<SuggestedLicenseConfirmationStepProps> = ({
  suggestedLicenseId,
  onConfirmed,
  onBack,
  disableNextButton,
}) => {
  return (
    <div
      cy-test-id="suggeted-license-confirmation-step"
      className="w-full flex flex-col gap-4"
    >
      <span className="inter text-white text-18 leading-28 font-medium">
        Based on your answers, the correct license for you is:
      </span>
      <LicenseDescription licenseId={suggestedLicenseId} />
      <ButtonsBlock
        onNext={() => onConfirmed(suggestedLicenseId)}
        onBack={onBack}
        nextButtonText="Confirm & continue"
        nextDisabled={disableNextButton}
      />
    </div>
  )
};

export default SuggestedLicenseConfirmationStep;
