import { PaymentFormProps } from '../../types/components/payment/PaymentForm';
import { PaymentMethod } from '../../types/ui/paymentMethod';

import PaymentMethodSelection from './PaymentMethodSelection';
import PaymentMethodComponent from './PaymentMethod';

import { useState } from 'react';

const PaymentForm: React.FC<PaymentFormProps> = (props: PaymentFormProps) => {
  const { purchaseUnderLicenseId, defaultPaymentMethod, onPaymentMethodSelected } = props;
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<PaymentMethod>(defaultPaymentMethod);

  const paymentMethodSelectionHandler = (paymentMethod: PaymentMethod) => {
    setSelectedPaymentMethod(paymentMethod);
    if (!onPaymentMethodSelected) return;
    onPaymentMethodSelected(paymentMethod);
  }

  return (
    <div className="w-full block" cy-test-id="payment-form">
      <PaymentMethodSelection
        onPaymentMethodSelected={paymentMethodSelectionHandler}
        defaultSelection={selectedPaymentMethod}
        purchaseUnderLicenseId={purchaseUnderLicenseId}
      />
      <PaymentMethodComponent selectedPaymentMethod={selectedPaymentMethod} />
    </div>
  );
}

export default PaymentForm;
